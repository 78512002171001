import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import countryList from 'country-list';
import { gettoken } from "../Localstorage/Store";
import { FaDeleteLeft } from "react-icons/fa6";
import { useDeleteCartMutation, useGetCartProductQuery } from "../store/api/cartapi";
import { addItem } from "../store/state/cart";
import { useDispatch, useSelector } from "react-redux";
import { IoIosCloseCircleOutline } from "react-icons/io";

const OrderHistoryDetails = () => {
  const [showtax, setshowtax] = useState(false);
  const [loading, setloading] = useState(false);
  const [vouchervalue, setvouchervalue] = useState('');
  const [totalitemvalue, settotalitemvalue] = useState(0);
  const [discountval, setdiscountval] = useState(0);
  const [totalmrp, settotalmrp] = useState(0);
  const [data, setData] = useState([]);
  const [voucherlist, setvoucherlist] = useState([]);
  const [voucherlist2, setvoucherlist2] = useState([]);
  const [cartinfo, setcartinfo] = useState({});
  const tokenvalue = gettoken();
  const nvg = useNavigate();
  const location = useLocation();
  const gobalvariable = useSelector(state => state);
  const dispatch = useDispatch();
  const { orderid } = useParams()
  const [singleorder, setsingleorder] = useState({})
  const [isshoppingvoucher, setisshoppingvoucher] = useState([]);
  const [offeramount_totalwithqty, setofferamount_totalwithqty] = useState(0);
  const [qty_total, setqty_total] = useState(0);
  const [taxable_total, settaxable_total] = useState(0);
  const [gst_total, setgst_total] = useState(0);
  const [offerSum, setOfferSum] = useState(0);

  const calculateOfferSum = (data) => {
    const sum = data.reduce((acc, item) => acc + item.offer, 0);
    setOfferSum(sum);
  };

  // console.log("order info", singleorder);
  useEffect(() => {
    async function orderdetail() {
      const config = {
        headers: {
          Authorization: `Bearer ${tokenvalue.access}`,
          'Content-Type': 'application/json',
        },
      };
      // let url = `${process.env.REACT_APP_API_URL}homepageapi/GetVouchersView`;
      let url = `${process.env.REACT_APP_API_URL}my_api/get-single_order/${orderid}`;
      try {
        setloading(false)
        const response = await axios.get(url, config);
        setsingleorder(response.data)

        let shoppingvoucher = response.data.voucher_list.filter((item) => item.voucher_type == "Shopping Voucher - Exclusive" || item.voucher_type == "Gift Voucher - Exclusive");
        setisshoppingvoucher(shoppingvoucher);
        // console.log("order s", response.data)
        if (response.data.product_deatils) {
          const updatedProducts = response.data.product_deatils.map(product => ({
            ...product,
            calculatedPercentOfMRP: product.discount_type == "percentage" ? (product.discount / 100) * product.mrp * product.quantity : product.discount * product.quantity,
          }));
          const totalPrice = updatedProducts.reduce((acc, product) => acc + product.calculatedPercentOfMRP, 0);
          const totalmrp = updatedProducts.reduce((acc, product) => acc + (product.mrp * product.quantity), 0);
          const totalitem = updatedProducts.reduce((acc, product) => acc + (1 * product.quantity), 0);
          const offerpricewithqty = updatedProducts.reduce((total, item) => total + (item.price_excl_tax * item.quantity), 0);
          const gsttotal = updatedProducts.reduce((total, item) => total + (item.gst_tax_amount * item.quantity), 0);
          const taxabletotal = updatedProducts.reduce((total, item) => total + (item.base_price * item.quantity), 0);
          const qtytotal = updatedProducts.reduce((total, item) => total + item.quantity, 0);
          // const setofferamount_totalwithqty = updatedProducts.reduce((total, item) => total + item.price_incl_tax, 0);
          setgst_total(gsttotal)
          setvoucherlist2(response.data.voucher_list)
          calculateOfferSum(response.data.voucher_list)
          settaxable_total(taxabletotal)
          setqty_total(qtytotal)
          setofferamount_totalwithqty(offerpricewithqty)
          settotalitemvalue(totalitem)
          setdiscountval(totalPrice)
          settotalmrp(totalmrp)
          setData(updatedProducts);
          setcartinfo(response.data.product_deatils);
          setloading(true)
        }

      } catch (error) {
        setloading(true)
      }
    }

    orderdetail()
  }, [])



  const [trackingDatatwo, setTrackingDatatwo] = useState({
    trackingData: {
      track_status: 1,
      shipment_status: 7,
      shipment_track: [
        {
          id: 236612717,
          awb_code: "141123221084922",
          courier_company_id: 51,
          shipment_id: 236612717,
          order_id: 237157589,
          pickup_date: "2022-07-18 20:28:00",
          delivered_date: "2022-07-19 11:37:00",
          weight: "0.30",
          packages: 1,
          current_status: "Delivered",
          delivered_to: "Chittoor",
          destination: "Chittoor",
          consignee_name: "",
          origin: "Banglore",
          courier_agent_details: null,
          courier_name: "Xpressbees Surface",
          edd: null,
          pod: "Available",
          pod_status: "https://s3-ap-southeast-1.amazonaws.com/kr-shipmultichannel/courier/51/pod/141123221084922.png"
        }
      ],
      shipment_track_activities: [
        {
          date: "2022-07-19 11:37:00",
          status: "DLVD",
          activity: "Delivered",
          location: "MADANPALLI, Madanapalli, ANDHRA PRADESH",
          "sr-status": "7",
          "sr-status-label": "DELIVERED"
        },
        {
          date: "2022-07-19 08:57:00",
          status: "OFD",
          activity: "Out for Delivery Out for delivery: 383439-Nandinayani Reddy Bhaskara Sitics Logistics  (356231) (383439)-PDS22200085719383439-FromMob , MobileNo:- 9963133564",
          location: "MADANPALLI, Madanapalli, ANDHRA PRADESH",
          "sr-status": "17",
          "sr-status-label": "OUT FOR DELIVERY"
        },
        {
          date: "2022-07-19 07:33:00",
          status: "RAD",
          activity: "Reached at Destination Shipment BagOut From Bag : nxbg03894488",
          location: "MADANPALLI, Madanapalli, ANDHRA PRADESH",
          "sr-status": "38",
          "sr-status-label": "REACHED AT DESTINATION HUB"
        },
        {
          date: "2022-07-18 21:02:00",
          status: "IT",
          activity: "InTransit Shipment added in Bag nxbg03894488",
          location: "BLR/FC1, BANGALORE, KARNATAKA",
          "sr-status": "18",
          "sr-status-label": "IN TRANSIT"
        },
        {
          date: "2022-07-18 20:28:00",
          status: "PKD",
          activity: "Picked Shipment InScan from Manifest",
          location: "BLR/FC1, BANGALORE, KARNATAKA",
          "sr-status": "6",
          "sr-status-label": "SHIPPED"
        },
        {
          date: "2022-07-18 13:50:00",
          status: "PUD",
          activity: "PickDone",
          location: "RTO/CHD, BANGALORE, KARNATAKA",
          "sr-status": "42",
          "sr-status-label": "PICKED UP"
        },
        {
          date: "2022-07-18 10:04:00",
          status: "OFP",
          activity: "Out for Pickup",
          location: "RTO/CHD, BANGALORE, KARNATAKA",
          "sr-status": "19",
          "sr-status-label": "OUT FOR PICKUP"
        },
        {
          date: "2022-07-18 09:51:00",
          status: "DRC",
          activity: "Pending Manifest Data Received",
          location: "RTO/CHD, BANGALORE, KARNATAKA",
          "sr-status": "NA",
          "sr-status-label": "NA"
        }
      ],
      track_url: "https://shiprocket.co//tracking/141123221084922",
      etd: "2022-07-20 19:28:00",
      qc_response: {
        qc_image: "",
        qc_failed_reason: ""
      }
    }
  });


  const [showtrack, setshowtrack] = useState(false);
  // console.log(showtrack, "showtrack_________===={{{{+++")
  const [awbNumber, setawbNumber] = useState(null);

  const [trackingData, setTrackingData] = useState({});

  console.log(trackingData, "filterd data")

  const fetchTrackingData = async (item) => {
    setawbNumber(item);
console.log("skskskkskks",item)
    try {
      const url = `${process.env.REACT_APP_API_URL}ship/track-order-now?awb=${item}`;
      const response = await axios.get(url);

      if (response.data) {
        // Assuming response.data is a JSON string
        const jsonData = JSON.parse(response.data);
        console.log("gddffddgdg dfdd",jsonData)
        setTrackingData(jsonData);
        setshowtrack(true);
      } else {
        setshowtrack(false);
      }
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setshowtrack(false);
    }
  };



  return (
    loading == false ? <></> : <>
      <Header />


      <div className="breadcrumb-main marginfromtop" style={{ background: "#fff" }}>
        <div className="container m-0">
          <div className="row">
            <div className="col">
              <div className="breadcrumb-contain">
                <div>
                  <ul>
                    <li><a href="/">home</a></li>
                    <li>{`>`}</li>
                    <li><a href="javascript:void(0)">Order Detail</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*section start*/}
      <section className="section-big-py-space b-g-light ">
        <div className="container-fluid d-flex justify-content-center">
          <div className="row justify-content-center definewidth" >
            <div className="col-md-8">

              <div className="table-responsive hist" style={{ borderRadius: "6px" }}>
                <h5 style={{ padding: '9px 9px', fontWeight: 400, fontSize: 14, paddingBottom: '20px', cursor: 'pointer' }} onClick={() => { nvg("/order-history") }}><img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/Arrow 1.png`} alt /> Back to list</h5>
                <div className=""><h4 className="Orderstatus addmedia" style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '0px 9px', fontWeight: 700, fontSize: "13px", paddingBottom: '20px', paddingTop: '10px' }}>Order Id: {orderid}    <span> {new Date(singleorder.order_date.split('Time')[0]).toLocaleDateString('en-GB', { hour: 'numeric', minute: 'numeric' })}</span>   <span style={{ display: 'block', color: 'black', textTransform: 'capitalize' }}>Order Status: <img src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/icon/success.png`} alt="404" /> {singleorder.order_status}</span></h4></div>

                {/* <table className="table">
                  <thead className="two ">
                    <tr className="three" style={{ borderBottom: '1px solid #d0cbcb' }}>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>Image</th>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>Product Name </th>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>MRP</th>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>Price</th>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>Quantity</th>
                      <th className="family px-3" style={{ fontWeight: 600, fontSize: "14px", textAlign: 'center' }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {singleorder.product_deatils.map((item) => (
                      <tr>
                        <td className="px-3"><img src={`${process.env.REACT_APP_API_URL}media/${item.image?.[0]?.original}`}
                          width="80px"
                          height="80px" alt="cart" className=" " /></td>
                        <td style={{ padding: '16px 0px' }} className="pnwidth px-3"><div><span style={{ color: 'black', fontSize: "14px", lineHeight: item?.product__title.length > 23 ? "24px" : "33px", textAlign: 'center', position: 'relative', top: '5px' }}>{item?.product__title} </span> </div>
                        { (singleorder.order_status !== "Delivery" && singleorder.order_status !== "Cancelled") && item.awb && (
                          <div style={{ color: 'black', fontSize: "14px", lineHeight: item.product__title.length > 23 ? "24px" : "33px", textAlign: 'start', position: 'relative', top: '5px' }}>
                            <span onClick={() => fetchTrackingData(item.awb)}>
                              Track Awb : <span style={{ padding: '16px 0px', color: 'blue', cursor: 'pointer' }}>{item.awb}</span>
                            </span>
                          </div>
                        )}



                        </td>

                        <td style={{ padding: '16px 0px' }}><h6 className="td-color px-2" style={{ fontWeight: 400, lineHeight: "33px", fontSize: "14px", textAlign: 'center' }}>₹{item?.mrp}</h6></td>
                        <td style={{ padding: '16px 0px' }}><h6 className="td-color px-2" style={{ fontWeight: 400, lineHeight: "33px", fontSize: "14px", textAlign: 'center' }}>₹{item?.price_incl_tax}</h6></td>

                        <td className="td-color px-3" style={{ fontWeight: 400, lineHeight: "33px", fontSize: "14px", padding: '16px 0px' }}> <span><div style={{ display: 'flex', justifyContent: 'center' }}>{item?.quantity}</div></span> </td>

                        <td><h6 className="td-color px-2" style={{ fontWeight: 400, lineHeight: "33px", fontSize: "14px", color: '#230BB3', textAlign: 'center', padding: '6px 0px' }}>₹{(item?.price_incl_tax * item?.quantity).toFixed(2)}</h6></td>
                      </tr>
                    ))}

                  </tbody>
                </table> */}






<table className="table">
                      <thead>
                        <tr>
                          <th
                            className="family"
                            style={{
                              fontWeight: 600,
                              fontSize: "13px",
                              width: "15%",
                              textAlign: "center",
                            }}
                          >
                            Image
                          </th>
                          <th
                            className="family"
                            style={{
                              fontWeight: 600,
                              fontSize: "13px",
                              width: "12%",
                              textAlign: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Product Name
                          </th>
                          {/* <th
                            className="family"
                            style={{
                              fontWeight: 600,
                              fontSize: "14px",
                              width: "15%",
                              textAlign: "center",
                            }}
                          >
                            MRP
                          </th> */}
                          <th
                            className="family"
                            style={{
                              fontWeight: 600,
                              fontSize: "13px",
                              width: "16%",
                              textWrap:'nowrap',
                              textAlign: "center",
                            }}
                          >
                            {/* Unit Price */}
                            Offer Price
                          </th>
                          {voucherlist2[0]?.voucher_type == "Gift Voucher - Inclusive" || voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive" ? <th
                            className="family"
                            style={{
                              fontWeight: 600,
                              fontSize: "13px",
                              width: "16%",
                              textWrap:'nowrap',
                              textAlign: "center",
                            }}
                          >
                            {/* Unit Price */}
                            Discount
                          </th> : ''}
                          
                          <th
                            className="family"
                            style={{
                              fontWeight: 600,
                              fontSize: "13px",
                              width: "15%",
                              textAlign: "center",
                            }}
                          >
                            Quantity
                          </th>
                          <th
                            className="family"
                            style={{
                              fontWeight: 600,
                              fontSize: "13px",
                              width: "18%",
                              textWrap:'nowrap',
                              textAlign: "center",
                            }}
                          >
                            {/* Basic Amount */}
                            Total Amount
                          </th>
                         
                          <th
                            className="family"
                            style={{
                              fontWeight: 600,
                              fontSize: "13px",
                              width: "18%",
                              textWrap:'nowrap',
                              textAlign: "center",
                            }}
                          >
                            {/* Tax Type */}
                            Taxable Value
                          </th>
                          <th
                            className="family"
                            style={{
                              fontWeight: 600,
                              fontSize: "13px",
                              width: "18%",
                              textWrap:'nowrap',
                              textAlign: "center",
                            }}
                          >
                           GST Rate
                          </th>
                          <th
                            className="family"
                            style={{
                              fontWeight: 600,
                              fontSize: "13px",
                              width: "18%",
                              textWrap:'nowrap',
                              textAlign: "center",
                            }}
                          >
                            GST Amount
                          </th>
                          
                          
                        </tr>
                      </thead>
                      <tbody>
                        {data[0]?.product__title ? (
                          data.map((item, index) => (<>
                            <tr>
                              <td
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    nvg("/productdetails", {
                                      state: {
                                        id: item.product,
                                        pname: item.product__title,
                                      },
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}media/${item.image[0].original}`}
                                    width="80px"
                                    height="80px"
                                    alt="cart"
                                    className=" "
                                  />
                                </div>
                              </td>
                              <td
                                className="pnwidth"
                                style={{ position: "relative", top: "0px" }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    top: "0px",
                                  }}
                                >
                                  <h6>
                                    <p
                                      style={{
                                        color: "black",
                                        fontSize: "13px",
                                        // lineHeight: "22px",
                                        lineHeight:
                                          item.stock == 0 ? "40px" : "63px",
                                        textAlign: "center",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "1",
                                        WebkitBoxOrient: "vertical",
                                        cursor: "pointer",
                                        overflow: "hidden",
                                        position: "relative",
                                        top: "8px",
                                      }}
                                      onClick={() => {
                                        nvg("/productdetails", {
                                          state: {
                                            id: item.product,
                                            pname: item.product__title,
                                          },
                                        });
                                      }}
                                    >
                                      {/* {item.product__title.length > 10 ? item.product__title.substring(0,11) + '...' : item.product__title} */}

                                      {item.product__title}
                                    </p>
                                    {/* <p
                                      style={{
                                        color: "red",
                                        fontSize: "13px",
                                        // lineHeight: "22px",
                                        // lineHeight: "63px",
                                        textAlign: "center",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "1",
                                        WebkitBoxOrient: "vertical",
                                        overflow: "hidden",
                                        position: "relative",
                                        top: "0px",
                                      }}
                                    >
                                      {" "}
                                      {item.stock == 0 ? "(Out of Stock)" : ""}
                                    </p> */}
                                    <p
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        // lineHeight: "22px",
                                        // lineHeight: "63px",
                                        textAlign: "center",
                                        // display: "-webkit-box",
                                        // WebkitLineClamp: "1",
                                        // WebkitBoxOrient: "vertical",
                                        // overflow: "hidden",
                                        position: "relative",
                                        top: "0px",
                                      }}
                                    >
                                      {" "}
                        { (singleorder.order_status !== "Delivery" && singleorder.order_status !== "Cancelled") && item.awb && (
                          <div style={{ color: 'black', fontSize: "14px", lineHeight: item.product__title.length > 23 ? "24px" : "33px", textAlign: 'start', position: 'relative', top: '-19px',left:'8px' }}>
                            <span onClick={() => fetchTrackingData(item.awb)}>
                              Track Awb : <span style={{position:'relative',top:'-5px', color: 'blue', cursor: 'pointer' }}>{item.awb}</span>
                            </span>
                          </div>
                        )}



                                    </p>
                                  </h6>
                                </div>
                              </td>
                              {/* <td
                                style={{ position: "relative", padding: "0px" }}
                              >
                                <h6
                                  className="td-color"
                                  style={{
                                    // position:'relative',
                                    // top:'12px',
                                    fontWeight: 400,
                                    lineHeight: "63px",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    position: "absolute",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    top: "8px",
                                  }}
                                >
                                  ₹{item.mrp}
                                </h6>
                              </td> */}
                              <td
                                style={{ position: "relative", padding: "0px" }}
                              >
                                <h6
                                  className="td-color"
                                  style={{
                                    // position:'relative',
                                    // top:'8px',
                                    fontWeight: 400,
                                    lineHeight: "63px",
                                    fontSize: "13px",
                                    textAlign: "center",
                                    textAlign: "center",
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    top: "8px",
                                  }}
                                >
                                  {/* ₹{item.base_price} old */}
                                  ₹{item.price_excl_tax}
                                </h6>
                              </td>
                             {voucherlist2[0]?.voucher_type == "Gift Voucher - Inclusive" || voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive" ? <td
                                style={{ position: "relative", padding: "0px" }}
                              >
                                <h6
                                  className="td-color"
                                  style={{
                                    // position:'relative',
                                    // top:'8px',
                                    fontWeight: 400,
                                    lineHeight: "63px",
                                    fontSize: "13px",
                                    textAlign: "center",
                                    textAlign: "center",
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    top: "8px",
                                  }}
                                >
                                  {/* ₹{item.base_price} old */}
                                  {/* ₹{item.price_excl_tax} */}
                                  ₹{Number.isInteger((item.amount_allocation_by_line)) ? (
                                        item.amount_allocation_by_line) : (item.amount_allocation_by_line).toFixed(2)}
                                </h6>
                              </td> : ''} 
                              <td className="td-color px-3" style={{ fontWeight: 400, lineHeight: "33px", fontSize: "14px", padding: '16px 0px' }}> <span><div style={{ display: 'flex', justifyContent: 'center',position:'relative',top:'6px' }}>{item?.quantity}</div></span> </td>
                              <td
                                style={{ position: "relative", padding: "0px" }}
                              >
                                <h6
                                  className="td-color px-lg-0 px-md-2"
                                  style={{
                                    fontWeight: 400,
                                    lineHeight: "63px",
                                    fontSize: "13px",
                                    // color: "#230BB3",
                                    textAlign: "center",
                                    textAlign: "center",
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    top: "8px",
                                  }}
                                >
                                  ₹
                                  {/* {(
                                   parseInt(item.base_price) * parseInt(item.quantity)
                                  ).toFixed(0)}{" "} old */}
                                  {(
                                   parseInt(item.price_excl_tax) * parseInt(item.quantity)
                                  ).toFixed(0)}{" "}

                                  
                                </h6>
                              </td>
                              
                              <td
                                style={{ position: "relative", padding: "0px" }}
                              >
                                <h6
                                  className="td-color px-lg-0 px-md-2"
                                  style={{
                                    fontWeight: 400,
                                    lineHeight: "63px",
                                    fontSize: "13px",
                                    // color: "#230BB3",
                                    textAlign: "center",
                                    textAlign: "center",
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    top: "8px",
                                  }}
                                >
                                  {/* IGST */}
                                  ₹
                                  {/* {(parseFloat(item.base_price) * parseInt(item.quantity)).toFixed(0)} */}



                                  {Number.isInteger((item.base_price *
                                        item.quantity
                                      )) ? (
                                        item.base_price *
                                        item.quantity
                                      ) : (
                                        item.base_price *
                                        item.quantity
                                      ).toFixed(2)}
                                  {/* <span style={{ color: "#52bb56" }}>
                                    &nbsp;&nbsp;
                                    {item.voucher?.[0] ? "Applied" : ""}
                                  </span> */}
                                </h6>
                              </td>
                              <td
                                style={{ position: "relative", padding: "0px" }}
                              >
                                <h6
                                  className="td-color px-lg-0 px-md-2"
                                  style={{
                                    fontWeight: 400,
                                    lineHeight: "63px",
                                    fontSize: "13px",
                                    // color: "#230BB3",
                                    textAlign: "center",
                                    textAlign: "center",
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    top: "8px",
                                  }}
                                >
                                  
                                  {item.gst_rate}%
                                  {/* <span style={{ color: "#52bb56" }}>
                                    &nbsp;&nbsp;
                                    {item.voucher?.[0] ? "Applied" : ""}
                                  </span> */}
                                </h6>
                              </td>
                              <td
                                style={{ position: "relative", padding: "0px" }}
                              >
                                <h6
                                  className="td-color px-lg-0 px-md-2"
                                  style={{
                                    fontWeight: 400,
                                    lineHeight: "63px",
                                    fontSize: "13px",
                                    // color: "#230BB3",
                                    textAlign: "center",
                                    textAlign: "center",
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    top: "8px",
                                  }}
                                >
                                  {/* ₹{item.gst_tax_amount * item.quantity} */}
                                  ₹{Number.isInteger(item.gst_tax_amount * item.quantity) ? (item.gst_tax_amount * item.quantity) : (item.gst_tax_amount * item.quantity).toFixed(2)}

                                  {/* <span style={{ color: "#52bb56" }}>
                                    &nbsp;&nbsp;
                                    {item.voucher?.[0] ? "Applied" : ""}
                                  </span> */}
                                </h6>
                              </td>
                              {/* <td
                                style={{ position: "relative", padding: "0px" }}
                              >
                                <h6
                                  className="td-color px-lg-0 px-md-2"
                                  style={{
                                    fontWeight: 400,
                                    lineHeight: "63px",
                                    fontSize: "14px",
                                    textAlign: "center",
                                    textAlign: "center",
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    top: "8px",
                                  }}
                                >
                                  ₹{parseInt((item.price_incl_tax * item.quantity).toFixed(0))}
                                </h6>
                              </td> */}
                            
                            </tr>
                            <tr style={{display:(data.length - 1) == index ? "" : "none" }}>
                              <td colSpan={voucherlist2[0]?.voucher_type == "Gift Voucher - Inclusive" || voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive" ? 4 : 3} style={{textAlign:'center',fontSize:'12px',fontWeight:'600'}}>TOTAL</td>
                              <td style={{textAlign:'center',fontSize:'12px',fontWeight:'600'}} >{qty_total}</td>
                              <td style={{textAlign:'center',fontSize:'12px',fontWeight:'600'}} >₹{offeramount_totalwithqty}</td>
                              <td style={{textAlign:'center',fontSize:'12px',fontWeight:'600'}} >₹{taxable_total.toFixed(0)}</td>
                              <td style={{textAlign:'center',fontSize:'12px',fontWeight:'600'}} ></td>
                              <td style={{textAlign:'center',fontSize:'12px',fontWeight:'600'}} >₹{gst_total.toFixed(0)}</td>
                              {/* <td style={{textAlign:'center',fontSize:'12px',fontWeight:'600'}} ></td> */}
                                </tr>
                                </>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="9">
                              <h6
                                className="td-color"
                                style={{
                                  fontWeight: 400,
                                  lineHeight: "34px",
                                  fontSize: "14px",
                                  color: "#230BB3",
                                  textAlign: "center",
                                }}
                              >
                                No Item Found
                              </h6>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>


                <br />
                {/* <h5 style={{ padding: '9px 9px', fontWeight: 400, fontSize: 14, paddingBottom: '20px' }}><img src="/images/Arrow 1.png" alt /> Back to Shop</h5> */}
              </div>










{/* {console.log("yyyy",voucherlist2)} */}
              {voucherlist2[0]?.offer ? <div style={{ backgroundColor: "white", padding: "0px 6px",marginBottom:"8px" }}>
                    <table className="table bg-white mt-3">
                    <tbody>
                        
                            <tr className="" style={{ borderColor: "#333" }}>
                              <td
                                style={{ fontWeight: "700", fontSize: "18px",display:'flex',gap:'19px',alignItems:'center',flexWrap:'wrap' }}
                              >
                                <span>
                               {/* {voucherlist2.length}  */}
                               Redeem  {voucherlist2[0]?.code ? voucherlist2[0]?.offer_type != "Coupon" ? `Voucher(s)` : `Coupon` : ''}
                                </span>
                                <div style={{ display: "flex", gap: 4 }}>
                        
                      </div>
                      {/* <span style={{ color: "red",fontSize:'12px',fontWeight:'500' }}>{vouchererror}</span> */}
                      {voucherlist2[0]?.code ? voucherlist2[0]?.offer_type != "Coupon" ? <span className="carttotalhere" style={{ color: "",fontSize:'12px',fontWeight:'500' }}>Voucher(s) Applied: <span style={{border: "1px solid #ced4da",padding: "2px 5px"}}>{voucherlist2.length}</span> : Value: <span style={{border: "1px solid #ced4da",padding: "2px 5px"}}>₹{offerSum}</span></span> : "" : ""}
                      {/* <span className="carttotalhere" style={{ color: "",fontSize:'12px',fontWeight:'500' }}>Voucher Total: ₹{offerSum}</span> */}
                              </td>
                              </tr>
                              </tbody>
                    </table>

                    <div className="newscrollerbar" style={{width:'100%'}}>
                    {voucherlist2[0]?.code ? voucherlist2[0]?.offer_type != "Coupon" ? <table className="table-responsive bg-white mt-3" style={{width:'100%'}}>
                    
                    <tbody>
                      {data[0]?.product__title ? (
                        <>
                
                            <tr>
                              <th style={{textAlign:'center',fontSize:'12px'}}>S.NO</th>
                              <th style={{textWrap:'nowrap',fontSize:'12px',width:'18%'}}>Voucher Code</th>
                              <th style={{textWrap:'nowrap',fontSize:'12px',width:'19%'}} className="px-lg-0 px-2">Voucher Type</th>
                              {/* <th style={{textWrap:'nowrap',fontSize:'12px'}} className="px-lg-0 px-2">Basic Amount</th> */}
                              <th style={{textWrap:'nowrap',fontSize:'12px',width:'18%'}} className="px-lg-0 px-2">GST</th>
                              <th style={{textWrap:'nowrap',fontSize:'12px',width:'18%'}} className="px-lg-0 px-2">Shipping</th>
                              <th style={{textWrap:'nowrap',fontSize:'12px'}} className="px-lg-0 px-2">Value</th>
                              <th></th>
                            </tr>
                             {voucherlist2[0]?.code
                            ? voucherlist2.map((item, index) => (
                                <tr >
                                  <td><span style={{display:'flex',width:'100%',justifyContent:'center'}}>{index + 1}</span></td>
                                  <td>
                                    <div>
                                      <div
                                        className="firstrow  py-1 justify-content-between  align-items-center"
                                        style={{
                                          display:"flex",
                                        }}
                                      >
                                        <div
                                          className="firstcontianer d-flex align-items-start"
                                          style={{ gap: 4 }}
                                        >
                                          <div className="containerimg">
                                            <img
                                              src="/images/carticon.png"
                                              style={{width:'15px'}}
                                              alt={404}
                                            />
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              gap: "4px",
                                              alignItems: "baseline",
                                              minWidth: "192px",
                                            }}
                                          >
                                            <p
                                              className="m-0"
                                              style={{
                                                fontWeight: 400,
                                                fontSize: "12px",
                                              }}
                                            >
                                              {item.code} 
                                              {/* applied &nbsp;{" "} */}
                                              {/* {item.voucher_type == "Shopping Voucher - Exclusive" || item.voucher_type == "Gift Voucher - Exclusive" ? "excl. GST"
                                                : "incl. GST"} */}
  








                                             
                                              
                                            </p>
                                          </div>
                                        </div>
                                        {/* <div
                                          className="remove"
                                          style={{
                                            flexDirection: "column",
                                            paddingRight: "0px",
                                            padding: "0px",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "#230BB3",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              position: "relative",
                                              top: "-2px",
                                            }}
                                          >
                                            ₹{item.offer}
                                          </span>
                                      
                                        </div> */}
                                      </div>{" "}
                                    </div>
                                  </td>
                                  {/* <td style={{fontSize:'12px'}}>
                                    Clubbable                                    </td>
                                  <td style={{fontSize:'12px'}}>Payable</td>
                                  <td style={{fontSize:'12px'}}>Payable</td> */}
                                  <td style={{fontSize:'12px'}}>
                                    {item.clubable ? "Clubbable" : "Nonclubbable"} 
                                  </td>
                             
                                  <td style={{fontSize:'12px'}}>{item.voucher_type == "Gift Voucher - Exclusive" || item.voucher_type == "Shopping Voucher - Exclusive" ? "Payable" : "Included"}</td>
                                  <td style={{fontSize:'12px'}}>  {item.shipping_incl == false ? "Payable" : "Included"}       </td>
                                  <td style={{fontSize:'12px'}}> ₹{item.offer}</td>
                                
                                </tr>
                              ))
                            : ""}

                        </>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table> : <table className="table-responsive bg-white mt-3" style={{width:'100%'}}>
                    
                    <tbody>
                      {data[0]?.product__title ? (
                        <>
                
                            <tr>
                              <th style={{textAlign:'center',fontSize:'12px',width:'8%'}}>S.NO</th>
                              <th style={{textWrap:'nowrap',fontSize:'12px',width:'18%'}}>Coupon Code</th>
                              <th style={{textWrap:'nowrap',fontSize:'12px'}} className="px-lg-0 px-2">Description                              </th>
                              {/* <th style={{textWrap:'nowrap',fontSize:'12px'}} className="px-lg-0 px-2">Basic Amount</th> */}
                              {/* <th style={{textWrap:'nowrap',fontSize:'12px'}} className="px-lg-0 px-2">GST</th>
                              <th style={{textWrap:'nowrap',fontSize:'12px'}} className="px-lg-0 px-2">Shipping</th> */}
                              <th style={{textWrap:'nowrap',fontSize:'12px',width:'8%'}} className="px-lg-0 px-2">Value</th>
                              <th></th>
                            </tr>
                             {voucherlist2[0]?.code
                            ? voucherlist2.map((item, index) => (
                                <tr >
                                  <td><span style={{display:'flex',width:'100%',justifyContent:'center'}}>{index + 1}</span></td>
                                  <td>
                                    <div>
                                      <div
                                        className="firstrow  py-1 justify-content-between  align-items-center"
                                        style={{
                                          display:"flex",
                                        }}
                                      >
                                        <div
                                          className="firstcontianer d-flex align-items-start"
                                          style={{ gap: 4 }}
                                        >
                                          <div className="containerimg">
                                            <img
                                              src="/images/carticon.png"
                                              style={{width:'15px'}}
                                              alt={404}
                                            />
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              gap: "4px",
                                              alignItems: "baseline",
                                              minWidth: "192px",
                                            }}
                                          >
                                            <p
                                              className="m-0"
                                              style={{
                                                fontWeight: 400,
                                                fontSize: "12px",
                                                position:'relative',
                                                top:'3px'
                                              }}
                                            >
                                              {item.code} 
                                              {/* applied &nbsp;{" "} */}
                                              {/* {item.voucher_type == "Shopping Voucher - Exclusive" || item.voucher_type == "Gift Voucher - Exclusive" ? "excl. GST"
                                                : "incl. GST"} */}
  








                                             
                                              
                                            </p>
                                          </div>
                                        </div>
                                        {/* <div
                                          className="remove"
                                          style={{
                                            flexDirection: "column",
                                            paddingRight: "0px",
                                            padding: "0px",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "#230BB3",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              position: "relative",
                                              top: "-2px",
                                            }}
                                          >
                                            ₹{item.offer}
                                          </span>
                                      
                                        </div> */}
                                      </div>{" "}
                                    </div>
                                  </td>
                                  {/* <td style={{fontSize:'12px'}}>
                                    Clubbable                                    </td>
                                  <td style={{fontSize:'12px'}}>Payable</td>
                                  <td style={{fontSize:'12px'}}>Payable</td> */}
                                  <td style={{fontSize:'12px'}}>
                                    {item.description.replace(/[\[\],\']/g,"")     } 
                                    {/* {item.clubable ? "Clubbable" : "Nonclubbable"}  */}
                                  </td>
                             
                                  {/* <td style={{fontSize:'12px'}}>{item.voucher_type == "Gift Voucher - Exclusive" || item.voucher_type == "Shopping Voucher - Exclusive" ? "Payable" : "Included"}</td>
                                  <td style={{fontSize:'12px'}}>  {item.shipping_charge == false ? "Payable" : "Included"}       </td> */}
                                  <td style={{fontSize:'12px'}}> ₹{item.offer}</td>
                                
                                </tr>
                              ))
                            : ""}

                        </>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table> : ''}
                    </div>
                  </div> : ''}







            </div>
            <div className=" col-lg-4 col-md-4 mt-lg-0 mt-md-0 mt-sm-3 ">
              <div
                // className="py-2 px-2"
                // style={{ background: "#ffff", borderRadius: 6 }}
              >                             
              
                {/* <div className="px-3"><h4 style={{ width: '100%', display: 'flex', justifyContent: 'space-between', fontWeight: 700, fontSize: "14px", paddingBottom: '20px', paddingTop: '10px' }}>Payment Summary <span style={{ display: 'block', color: '#8f9091' }}></span></h4></div> */}

                {/* <div
                  className="firstrow px-3 d-flex justify-content-between"
                  style={{ padding: "5px 0px" }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    Basic Amount ({totalitemvalue} Item(s))
       
                  </span>
                  <span
                    className="family"
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
         
                    ₹{voucherlist2[0] ? voucherlist2[0]?.voucher_type == "Gift Voucher - Inclusive" || voucherlist2[0]?.voucher_type == "Shopping Voucher - Inclusive" || voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive" || voucherlist2[0]?.voucher_type == "Shopping Voucher - Exclusive" ? (taxable_total).toFixed(0) : (taxable_total).toFixed(0) : totalitemvalue == 0 ? 0 : (totalmrp).toFixed(0)}

                  </span>
                </div> */}
                {/* <div
                  className="firstrow px-3 justify-content-between"
                  style={{ padding: "5px 0px", display: discountval == 0 ? "none" : "flex" }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    Discount
                  </span>
                  <span
                    className="family"
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >

                    {discountval == 0 ? `₹0` : `-₹${discountval}`}
                  </span>
                </div> */}
                {/* {    voucherlist2[0] ? singleorder.total_product_gst_value > 0 ? <div
                // {isshoppingvoucher[0]?.code ? singleorder.total_product_gst_value > 0 ? <div
                  className="firstrow px-3 d-flex justify-content-between"
                  style={{ padding: "5px 0px" }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    GST Amount
                  </span>
                  <span
                    className="family"
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                    +₹{singleorder.total_product_gst_value}
                  </span>
                </div> : '' : ''}
                <div
                  className="firstrow px-3 d-flex justify-content-between"
                  style={{ padding: "5px 0px" }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    Shipping Charges
                  </span>
                  <span
                    className="family"
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                  +₹{singleorder?.shiping_charge}
                  </span>
                </div>
                {singleorder?.credit ? <div
                  className="firstrow px-3 d-flex justify-content-between"
                  style={{ padding: "5px 0px" }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    Credit
                  </span>
                  <span
                    className="family"
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                  -₹{Math.abs(singleorder?.credit)}
                  </span>
                </div> : ''} */}
                {/* {singleorder.voucher_list[0]?.offer ? <div
                  className="firstrow px-3 d-flex justify-content-between"
                  style={{ padding: "5px 0px", display: voucherlist2[0]?.offer ? 'block' : 'none' }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    Voucher Redemption
                  </span>
                  <span
                    style={{
                      color: "#230BB3",
                      fontWeight: 500,
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                  </span>
                </div> : ''} */}
                {/* {singleorder.voucher_list[0]?.offer ? singleorder.voucher_list.map((item, index) => (
                  <div className="firstrow px-2 mx-2 py-1 d-flex justify-content-between mt-1 align-items-center">
                    <div
                      className="firstcontianer d-flex align-items-start"
                      style={{ gap: 4 }}
                    >
                      <div className="containerimg">
                        <img src="/images/carticon.png" alt={404} />
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '4px', alignItems: 'baseline', minWidth: '192px' }}>
                        <p
                          className="m-0"
                          style={{ fontWeight: 400, fontSize: "12px" }}
                        >
                          {item.code} applied {item.voucher_type == 'Shopping Voucher - Exclusive' || item.voucher_type == 'Gift Voucher - Exclusive' ? 'excl. GST' : 'incl. GST'}
                        </p>
                      </div>
                    </div>
                    <div className="remove" style={{ flexDirection: 'column', paddingRight: '0px', padding: '0px' }}>
                      <span
                        style={{
                          color: "#230BB3",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        -₹{item.offer}
                      </span>
 
                    </div>
                  </div>
                )) : ''
                } */}



{/* {data.length > 0 ?  voucherlist2?.[0]?.voucher_type != "Gift Voucher - Exclusive" && voucherlist2?.[0]?.voucher_type != "Gift Voucher - Inclusive"
 ? voucherlist2[0]?.offer ? (
                      <div
                        className="firstrow px-3 d-flex justify-content-between"
                        style={{ padding: "5px 0px" }}
                      >
                        <span
                          className="family"
                          style={{ fontWeight: 600, fontSize: "12px" }}
                        >
                        {voucherlist2[0]?.voucher_type == "Gift Voucher - Inclusive" || voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive" ? "Voucher Discount" : "Voucher Amount"}  
                        </span>
                        <span
                          className="family"
                          style={{
                            fontWeight: 500,
                            color: "#230BB3",
                            fontSize: "12px",
                          }}
                        >
                 {`-₹${offerSum}`}
                        </span>
                      </div>
                    ) : "" : "" : ""} */}
                {/* {singleorder.voucher_list[0]?.offer ? <div
                  className="firstrow px-3 d-flex justify-content-between"
                  style={{ padding: "5px 0px" }}
                >
                  <span
                    className="family"
                    style={{ fontWeight: 600, fontSize: "12px" }}
                  >
                    Basic Amount
                  </span>
                  <span
                    className="family"
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                    ₹{singleorder?.basic_amount_radeemable}
                  </span>
                </div> : ''} */}
                
                {/* <div
                  className="firstrow px-3 justify-content-between"
                  style={{ padding: "5px 0px",display : tokenvalue.msg == "Voucher login" ? "none":"flex" }}
                >
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "12px",
                      paddingLeft: "0px",
                    }}
                  >
                    Credit Utilize
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                    ₹0{" "}
                    <hr
                      color="red"
                      style={{
                        margin: 0,
                        position: "relative",
                        top: 4,
                        opacity: 1,
                        color: "#2B2A29",
                        background: "#2B2A29",
                      }}
                    />
                  </span>
                </div> */}



                {/* <div className="firstrow px-3 pt-1 d-flex justify-content-between">
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: "12px",
                      paddingLeft: "0px",
                    }}
                  >
                        {voucherlist2[0]?.voucher_type == "Gift Voucher - Inclusive" || voucherlist2[0]?.voucher_type == "Gift Voucher - Exclusive" ? "Amount Payable" : "Balance Payable"}  
                    <span
                      style={{
                        fontSize: "10px",
                        color: "#8F9091",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setshowtax(!showtax);
                      }}
                    >
                      <span
                        style={{
                          fontSize: 10,
                          color: "#8F9091",
                          textAlign: 'end',
                          display: showtax == true ? "inline-block" : "none",
                        }}
                        id="span2"
                      >
                      ₹{singleorder.total_product_gst_value ? singleorder.total_product_gst_value : 0}
                      </span>
                    </span>
                  </span>
                  <span
                    style={{
                      fontWeight: 500,
                      color: "#230BB3",
                      fontSize: "12px",
                    }}
                  >
                    ₹{(singleorder.order_total_amount ? singleorder?.credit ? singleorder.order_total_amount -Math.abs(singleorder?.credit) : singleorder.order_total_amount : 0).toFixed(0)}
                  </span>
                </div> */}
                {/* <br />
                <hr /> */}

                {/* <div className="firstrow px-3 d-flex justify-content-between" style={{ padding: "5px 0px" }}>
                  <span className="family" style={{ fontWeight: 600, fontSize: "12px" }}>Payment Mode</span>
                  <span className="family" style={{ fontWeight: 500, color: '#8F9091', fontSize: '12px' }}>
                     {singleorder.payment_mode == "Razorpay" ? "Online Pay" : singleorder.payment_mode == "Wallet" ? "Wallet" : singleorder.payment_mode == "Custome" ? "Custom" : singleorder.payment_mode == "Credit" ? "Credit" : "COD"}</span>
                </div> */}
              </div>
              <div
                className="py-2 mt-lg-0 mt-md-3 mt-3"
                style={{ background: "#ffff", borderRadius: 6 }}
              >

               
                <div className="px-4" style={{}}>

                  {/* <h4 style={{ fontSize: '14px' }}>Billing Address</h4>
                  <p style={{ color: '#2B2A29', fontSize: '12px' }}>{singleorder.billing_address.first_name} {singleorder.billing_address.last_name}</p>
                  <p style={{ fontSize: '12px' }}> {singleorder.billing_address.line1}, {singleorder.billing_address.line2},
                      {countryList.getName(singleorder.billing_address.country)},
                    {singleorder.billing_address.state}, {singleorder.billing_address.line4}, {singleorder.billing_address.postcode} <br />
                  </p>


                  <p className="" style={{ fontSize: '12px' }}>
                    Mobile:{" "}
                    <span className="number2" style={{ color: '#2B2A29' }}>
                      {singleorder.shipping_address.phone_number}
                    </span>
                  </p>
                  <p className="" style={{ fontSize: '12px' }}>
                    Email ID:{" "}
                    <span className="number2" style={{ color: '#2B2A29' }} >
                      {singleorder.shipping_address.email}

                    </span>
                  </p> */}


<br />
                  <h4 style={{ fontSize: '14px' }}>Delivery Address</h4>
                  <p style={{ color: '#2B2A29', fontSize: '12px' }}>{singleorder.shipping_address.first_name} {singleorder.shipping_address.last_name}</p>
                  <p style={{ fontSize: '12px' }}> {singleorder.shipping_address.line1}, {singleorder.shipping_address.line2},  {countryList.getName(singleorder.shipping_address.country)},{singleorder.shipping_address.state}, {singleorder.shipping_address.line4}, {singleorder.shipping_address.postcode} <br />
                  </p>
                  <p className="" style={{ fontSize: '12px' }}>
                    Mobile:{" "}
                    <span className="number2" style={{ color: '#2B2A29' }}>
                      {singleorder.shipping_address.phone_number}
                    </span>
                  </p>
                  <p className="" style={{ fontSize: '12px' }}>
                    Email ID:{" "}
                    <span className="number2" style={{ color: '#2B2A29' }} >
                      {singleorder.shipping_address.email}

                    </span>
                  </p>

                  {/* <span style={{fontWeight: 600, fontSize: 12}}>Apply Credits</span>
<div style={{display: 'flex', gap: 4}}>
  <span style={{fontWeight: 700, color: '#230BB3'}}><input type="text" className="form-control applypay" placeholder="Enter your credit" /></span>
  <button className="btn" style={{fontWeight: 500, backgroundColor: '#230BB3', color: 'white', fontSize: 12, height: 'fit-content', padding: '7px 8px'}}>Apply</button>
</div> */}
                </div>
                {/* <div style={{display : tokenvalue.msg == "Voucher login" ? "none":"flex" }} className="firstrow px-3 pb-2 justify-content-between">
                  <span style={{ fontWeight: 600, fontSize: "17px" }} />
                  <span style={{ fontWeight: 500, fontSize: 11 }}>
                    Available Credit - ₹0
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {showtrack && (
        <div
          style={{
            position: 'fixed',
            top: 25,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000
          }}
        >


          <div style={{ maxWidth: '32rem', minWidth: '32rem', margin: '0 auto', backgroundColor: 'white', padding: '1.5rem', border: '1px solid #e5e7eb', borderRadius: '0.5rem', minHeight: '10rem', maxHeight: '30rem', overflowY: 'auto' }}>
            <div style={{
              display: 'flex', justifyContent: 'space-between',
            }}>
              <span></span>
              <span
                onClick={() => setshowtrack(false)}
                style={{
                  fontSize: '1.5rem',
                  color: 'red',
                  cursor: 'pointer'

                }}
              >
                <IoIosCloseCircleOutline />
              </span>

            </div>

            <div style={{ paddingBottom: '1rem', borderBottom: '1px solid #e5e7eb', marginBottom: '1rem' }}>
              <h2 style={{ fontSize: '1.125rem', fontWeight: '600' }}>Delivery by One Up Brands</h2>
              <p style={{ fontSize: '0.875rem', color: '#6b7280' }}>Tracking ID:</p> <span> {awbNumber}</span>
               <p style={{ fontSize: '0.875rem', color: '#6b7280',display:'inline' }}>Estimated Delivery Date:</p>  {trackingData.tracking_data.etd ? <span> {trackingData.tracking_data.etd.split(' ')[0]}</span> : 'Will be Updated After Pickup'}  
              <p style={{ fontSize: '0.75rem', color: '#9ca3af' }}>This is your One Up Brands tracking number.</p>
            </div>

            {trackingData && trackingData.tracking_data.shipment_track_activities ? (
              trackingData.tracking_data.shipment_track_activities.map((activity, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem' }}>
                  <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0.5rem' }}>
                    <p style={{ width: '5rem', fontSize: '0.75rem', color: '#6b7280' }}>
                      {activity.date ? new Date(activity.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '00:00'}
                    </p>
                  </div>

                  <div>
                    {activity.activity && (
                      <>
                        <p style={{ fontSize: '0.875rem', color: '#374151' }}>{activity.activity}</p>
                        <p style={{ fontSize: '0.75rem', color: '#9ca3af' }}>{activity.location || 'Warehouse'}</p>
                      </>
                    )}
                    {!activity.activity && (
                      <>
                        <p style={{ fontSize: '0.875rem', color: '#374151' }}>Unknown Activity</p>
                        <p style={{ fontSize: '0.75rem', color: '#9ca3af' }}>{activity.location || 'Warehouse'}</p>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div>
                <p>In Progress</p>
              </div>
            )}






          </div>
        </div>
      )}

      {/*section end*/}

      {/* <Footer /> */}
    </>
  );
};
export default OrderHistoryDetails;
