import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getfreeze, getrecetly, gettoken, removeToken, removefreeze, shippingidremove } from "../Localstorage/Store";
import axios from "axios";
import Subcategory from "./Subcategory";
import MobileSubcategory from "./MobileSubcategory";
import Cartcount from "../context/Cartcount";
import Recently from "../context/Recently";
import Wishlistcount from "../context/Wishlistcount";
import { useDispatch, useSelector } from "react-redux";
import { addwishlist } from "../store/state/wishlist";
import { addItem } from "../store/state/cart";
import Subcategorynew from "./Subcategorynew";
import MobileSubcategorynew from "./MobileSubcategorynew";

const Header = () => {
  const [showsidebar, setshowsidebar] = useState(false);
  const [showcontent, setshowcontent] = useState(false);
  const [acdropdown, setacdropdown] = useState(false);
  const [serchvalue, setserchvalue] = useState('');
  const userAgent = navigator.userAgent;
  // console.log("redddldllclclcl",userAgent.indexOf('Chrome'))
  const tokenvalue = gettoken()
  const cardRef = useRef(null)
  const searchRef = useRef(null)
  const recentlydata = getrecetly()
  const freezeid =getfreeze();
  const gobalvariable = useSelector(state => state);
  const dispatch = useDispatch();
  const nvg = useNavigate();
  // const [Cartnumber,setCartnumber] = useContext(Cartcount)
  // const [Wishlistnumber,setWishlistnumber] = useContext(Wishlistcount)
  // const [recentlyViewed, setRecentlyViewed] = useContext(Recently);
  const redirectfun = (linkpage) => {
    nvg(linkpage);
  };
  const location = useLocation();
  // console.log("ddddddddddddddddddddddddddddddd",location)
  const pagename = location.pathname
  console.log("tis is path nae ",pagename)
  const extractText = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return (doc.body.textContent).substring(0,15) || "";
  };

  const logoutfunction = () =>{
    removeToken();
    // nvg('/')
    // window.location.reload();
    window.location.href = "/";


  }
  const profilepage = (val) => {
    nvg("/profile", { state: { id: val } });
  };



  const unfreezestatus = async () => {
    console.log("this is unfreezw api")
    // if(freezeid){
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${tokenvalue.access}`,
    //     },
    //   };
    //   const formdata = {freeze_id_list:freezeid};
    //   let url = `${process.env.REACT_APP_API_URL}my_api/unfreeze-stock/`;
  
    //   const response = await axios.post(url,formdata, config);
    //   console.log("this is freeze api",response)
    //   removefreeze()

    // }

    var freezeid = getfreeze();

  //   if (freezeid) {
  //     // const tokenvalue = { access: 'your_access_token' }; // Replace with actual token retrieval logic
  //     const formdata = { freeze_id_list: freezeid };
  //     const url = `${process.env.REACT_APP_API_URL}my_api/unfreeze-stock/`;
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${tokenvalue.access}`,
  //         'Content-Type': 'application/json'
  //       },
  //     };

  //     const blob = new Blob([JSON.stringify(formdata)], { type: 'application/json' });

  //     // Use navigator.sendBeacon to send the data
  //     navigator.sendBeacon(url, blob);

  //     console.log("Freeze ID unfreeze request sent");
  //     removefreeze();

  // }



  // if(freezeid){
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${tokenvalue.access}`,
  //       },
  //     };
  //     const formdata = {freeze_id_list:freezeid};
  //     let url = `${process.env.REACT_APP_API_URL}my_api/unfreeze-stock/`;
  
  //     const response = await axios.post(url,formdata, config);
  //     console.log("this is freeze api",response)
  //     removefreeze()

    
  // }
}
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Your function to call the API
      unfreezestatus()
      // Prevent the default action to show a confirmation dialog
      // event.preventDefault();
      event.returnValue = ''; // Standard way to show confirmation dialog
    };
    unfreezestatus()

    shippingidremove()

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  useEffect(() => {
    const handleClickOutsideCard = (event) => {
      // Check if the click occurred outside the card
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        // Call your function here
        setacdropdown(false)
      }
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        // Call your function here
        setshowrecords(false)
      }
    };

    // Add click event listener to the entire document
    document.addEventListener('click', handleClickOutsideCard);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutsideCard);
    };
  }, []);
useEffect(()=>{
  window.scrollTo(0, 0);
},[])

  const transfer = (productid,pname)=>{
    nvg(`/categoryforsearch/${serchvalue}`);
    // nvg("/productdetails", {
    //   state: {
    //     id: productid,
    //     pname: pname,
    //   },
    // });
    window.location.reload();
    if(pagename == "/productdetails"){
      window.location.reload();
    }
  }

  const [searchdata, setsearchdata] = useState([]);
  const [showrecords, setshowrecords] = useState(false);
  const emptysearch = ()=>{
    setTimeout(() => {
      searchresult('')
      setserchvalue('')
    }, 500);
  }
  const searchresult = async (value) => {

    if(value == undefined || value == null || value == '' || value.length < 3){     
    setsearchdata([])
    }else{
    // let url = `${process.env.REACT_APP_API_URL}homepageapi/search/?q=${value}`;
    let url = `${process.env.REACT_APP_API_URL}homepageapi/search/${value}`;
    const response = await axios.get(url);
    // console.log("this is ral response",response);
    setsearchdata(response.data.results.results)

  }
   }
  
  
  const [categories, setCategories] = useState([]);
  const [categories2, setCategories2] = useState([]);
  useEffect(()=>{
   if(!tokenvalue){
    nvg('/')
   }




   async function Cartvalueget() {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
        'Content-Type': 'application/json',
      },
    };
  let url = `${process.env.REACT_APP_API_URL}homepageapi/CartCountOfUser`;
    try {
      const response = await axios.get(url, config);
      // setCartnumber(response.data.cart_count)
      dispatch(addItem(response.data.cart_count));

    } catch (error) {
      // console.log("error unsr",error.response.status)
      if(error.response.status == 401){
        removeToken()
        // window.location.reload();
    window.location.href = "/";

      }
    }
  }
   async function Wishvalueget() {
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
        'Content-Type': 'application/json',
      },
    };
  let url56 = `${process.env.REACT_APP_API_URL}homepageapi/GetCountOfWishlist`;
    try {
      const response = await axios.get(url56, config);
      // console.log("first vvvbbbbbbnnnnn super response",response);
      // setWishlistnumber(response.data.wishlist_count)
      dispatch(addwishlist(response.data.wishlist_count));

    } catch (error) {
    }
  }

  //  async function fetchcategory(){
  //   let url = `${process.env.REACT_APP_API_URL}api/categories/`;
  //   const response = await axios.get(url);
  //   setCategories(response.data)
  //  }
   async function fetchcategory2(){
    const config = {
      headers: {
        Authorization: `Bearer ${tokenvalue.access}`,
        'Content-Type': 'application/json',
      },
    };
    let url = `${process.env.REACT_APP_API_URL}cartapi/all-categoriies/`;
    const response = await axios.get(url,config);

    const orderedCategories = response.data.list.sort((a, b) => {
      const aChildCount = a.child.length;
      const bChildCount = b.child.length;

      // If a has more child items than b, place a before b
      if (aChildCount > bChildCount) {
          return -1;
      }
      // If b has more child items than a, place b before a
      else if (bChildCount > aChildCount) {
          return 1;
      }
      // If both have the same number of child items, maintain the original order
      else {
          return 0;
      }
  });

      
    setCategories2(orderedCategories)
    // console.log("reespone of new cate",response,orderedCategories)
   }
   setTimeout(() => {
    setshowcontent(true);
   }, 2000);
   fetchcategory2();
// fetchcategory();
Cartvalueget();
Wishvalueget();
  },[])

  // eader className="fixed-top" style={{ position: "sticky"
  return (
    <header className="fixed-top" style={{position: "fixed"}} >
      <div className="mobile-fix-option" />
      <div className="header7">
        <div className="custom-container">
          <div className="row">
            <div className="col-12">
              <div className="header-contain ">
                <div className="logo-block logowidth">
                  <div
                    className="mobilecat-toggle"
                    onClick={() => setshowsidebar(true)}
                  >
                    {" "}
                    <i className="fa fa-bars sidebar-bar" />
                  </div>
                  <div className="brand-logo logo-sm-center">
                    <NavLink to="/home" className="logoheight" >
                      <img
                        src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`}
                        className="img-fluid"
                        style={{height:'100%'}}
                        alt="logo"
                      />
                    </NavLink>
                  </div>
                </div>
                <div style={{position:'relative'}}>
                <div
                ref={searchRef}
                  className="header-search ajax-search the-basics dflex"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #cbc7c7",
                    padding: "0px 3px",
                    visibility:pagename == "/cart" ? "hidden" : ''
                  }}
                >
                  <div
                    className="input-group"
                    style={{ border: "1px solid white" }}
                  >
                    <input
                      type="text"
                      className="form-control newsizeinput"
                      style={{
                        border: "1px solid white",
                        background: "white",
                        padding: 0,
                        borderRadius: 0,
                        letterSpacing: 0,
                        fontSize:'14px',
                        textTransform:'none'
                      }}
                      value={serchvalue}
                      onChange={(e)=>{searchresult(e.target.value);setserchvalue(e.target.value);setshowrecords(e.target.value)}}
                      onBlur={()=>{emptysearch()}}
                      placeholder="Search For Product"
                    />
                  </div>
                  <div
                    className="input-group-text btn "
                    style={{
                      backgroundColor: "white",
                      padding: "0px 17px",
                      borderRadius: 0,
                      display: "flex",
                      border: "none",
                    }}
                  >
                    <span className="newfontsize"
                    //  onClick={()=>{setshowrecords(serchvalue !== '' ? !showrecords : false)}}
                     >
                      {" "}
                      <i
                        className="fa fa-search "
                        style={{ color: "rgba(35, 11, 179, 1)" }}
                      />
                    </span>
                  </div>
                </div>
                  <ul className="serachlisting"
                  style={{display:serchvalue.length > 2 ? "block" : 'none'}}
                  //  style={{display:showrecords == true ? 'block' : 'none'}}
                    >
                  {searchdata[0]?.title ? searchdata.map((item, index) => ( 
                    <li style={{width:'100%'}}>  
                      <div className="p-1 d-flex" style={{gap:"10px",cursor:'pointer'}} 
                      onClick={()=>{transfer(item.id,item.title)}} 
                      >
                        <div className="">
                        <img src={`${process.env.REACT_APP_API_URL}media/${item.image[0]?.original}`} style={{width:'70px'}} className="  " alt="product" />
                        </div>
                        <div className="cartinfo">
                         <h6 style={{fontSize:'12px',color:'#230bb3',fontWeight:'600',padding:'3px 0px'}}>{item.title}</h6>
                         <h6 style={{fontSize:'12px',color:'#230bb3',padding:'3px 0px'}}>₹{parseInt(item.stockrecords__price)}</h6>
                         {/* <h6 style={{fontSize:'12px',color:'#230bb3',padding:'3px 0px'}}>₹{item.price}</h6> */}
                         <p style={{fontSize:'11px',color:'#230bb3'}}>{extractText(item.description)}</p>
                        </div>
                      </div>
                    </li>
                        )) : serchvalue == '' ? '' : <li style={{width:'100%'}}>  
                        <div className="p-1 d-flex" style={{gap:"10px",padding:'4px 0px',cursor:'pointer',width:'100%',display:'flex',justifyContent:'center'}} >
          
                           <h6 style={{fontSize:'14px',color:'#333',fontWeight:'600',padding:'3px 0px'}}>No Record Found</h6>
                        
                        
                        </div>
                      </li>}
                  </ul>
                  </div>
                <div className="icon-block">
                  <ul className="theme-color" style={{visibility:pagename == "/cart" ? "hidden" : ''}}>
                    <li
                      className="mobile-user newposition2 item-count "
                      onClick={() => setacdropdown(!acdropdown)}
                    >
                      <div
                        className="dropdown show d-flex showaccountcontent"
                        style={{ flexDirection:'column',justifyContent: "center", alignItems: "center" }}
                        ref={cardRef}
                      >
                        <a
                          className="dropdown-toggle"
                          href="javascript:void(0)"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "end",
                          }}
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/mega-store/brand/profile.png`}
                            className="newwidthpro"
                            alt={404}
                          />
                        </a>
                        <span
                          className="mobilehide largesize"
                          style={{ color: "black",textAlign:'center',textWrap:'nowrap' }}
                        >
                          My Account
                        </span>
                        <div
                          className={
                            acdropdown == true
                              ? "dropdown-menu show"
                              : "dropdown-menu"
                          }
                          aria-labelledby="dropdownMenuLink"
                        >
                          {tokenvalue.msg == "Voucher login" ? "": <a
                            className="dropdown-item"
                            style={{ backgroundColor: "white", color: "black",fontSize:'12px' }}
                            onClick={() => {
                              nvg("/mycredit");
                            }}
                          >
                          My Credit 
                          </a> }
                          <a
                            className="dropdown-item"
                            style={{ backgroundColor: "white", color: "black",fontSize:'12px' }}
                            onClick={() => {
                              nvg("/order-history");
                            }}
                          >
                            My Order History
                          </a>
                          <a
                            className="dropdown-item"
                            style={{ backgroundColor: "white", color: "black",fontSize:'12px' }}
                            onClick={() => {
                              profilepage(1);
                              window.location.reload();
                            }}
                          >
                           My Profile
                          </a>
                         
                          {/* {tokenvalue.msg == "Voucher login" ? "": <a
                            className="dropdown-item"
                            style={{ backgroundColor: "white", color: "black",fontSize:'12px' }}
                            onClick={() => {
                              nvg("/voucher-request");
                            }}
                          >
                            Voucher Request
                          </a> } */}
                          
                          
                          {/* <a
                            className="dropdown-item"
                            style={{ backgroundColor: "white", color: "black",fontSize:'12px',display : tokenvalue.msg == "Voucher login" ? "none":"flex" }}
                            onClick={() => {
                              nvg("/credit");
                            }}
                          >
                            Credit History
                          </a> */}
                          {/* <a
                            className="dropdown-item"
                            style={{ backgroundColor: "white", color: "black",fontSize:'12px' }}
                            onClick={() => {
                              profilepage(3);
                              window.location.reload();
                            }}
                          >
                            Addresses List
                          </a> */}
                          {/* {tokenvalue.msg != "Voucher login" ? "": <a
                            className="dropdown-item"
                            style={{ backgroundColor: "white", color: "black",fontSize:'12px' }}
                            onClick={() => {
                              profilepage(4);
                              window.location.reload();
                            }}
                          >
                            Voucher History
                          </a>} */}
                          <NavLink className="dropdown-item" style={{fontSize:'12px'}} onClick={logoutfunction} to="/">
                            Logout
                          </NavLink>
                        </div>
                      </div>
                    </li>


                    <li className="mobile-wishlist item-count" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                      <button
                        type="button"
                        className="hidecontent"
                        style={{
                          border: "none",
                          outline: "none",
                          backgroundColor: "white",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModaltwo"
                      >
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/mega-store/brand/search.png`}
                          className="newwidthpro"
                          alt={404}
                        />
                      </button >
                      <NavLink to="/wishlist" className="showaccountcontent">
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/mega-store/brand/wishlist.png`}
                          className="newwidthpro"
                          alt={404}
                        />
                        <div className="item-count-contain inverce">{gobalvariable.wishlist}</div>
                      </NavLink>
                      <span
                      className="mobilehide largesize "
                      onClick={() => {
                        redirectfun("/wishlist");
                      }}
                      style={{
                        color: "black",
                        cursor: "pointer",
                        // marginLeft: "-13px",
                        // paddingLeft: 4,
                        textAlign:'center'
                      }}
                    >
                      Wish List
                    </span>
                    </li>
                    
                    <li className="mobile-cart item-count showaccountcontent">
                      <NavLink to="/cart">
                        {" "}
                        <img
                          src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/mega-store/brand/cart.png`}
                          className="newwidthpro"
                          alt={404}
                        />
                        <div className="item-count-contain inverce">{gobalvariable.cart}</div>
                      </NavLink>
                    <span
                      className="mobilehide largesize"
                      onClick={() => {
                        nvg("/cart");
                      }}
                      style={{
                        color: "black",
                        cursor: "pointer",
                        // marginLeft: "-13px",
                        // paddingLeft: 4,
                        textAlign:'center'
                      }}
                    >
                      My Cart
                    </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="searchbar-input">
          <div className="input-group">
            <div className="input-group-append">
              <span className="input-group-text">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="28.931px"
                  height="28.932px"
                  viewBox="0 0 28.931 28.932"
                  style={{ enableBackground: "new 0 0 28.931 28.932" }}
                  xmlSpace="preserve"
                >
                  <g>
                    <path d="M28.344,25.518l-6.114-6.115c1.486-2.067,2.303-4.537,2.303-7.137c0-3.275-1.275-6.355-3.594-8.672C18.625,1.278,15.543,0,12.266,0C8.99,0,5.909,1.275,3.593,3.594C1.277,5.909,0.001,8.99,0.001,12.266c0,3.276,1.275,6.356,3.592,8.674c2.316,2.316,5.396,3.594,8.673,3.594c2.599,0,5.067-0.813,7.136-2.303l6.114,6.115c0.392,0.391,0.902,0.586,1.414,0.586c0.513,0,1.024-0.195,1.414-0.586C29.125,27.564,29.125,26.299,28.344,25.518z M6.422,18.111c-1.562-1.562-2.421-3.639-2.421-5.846S4.86,7.983,6.422,6.421c1.561-1.562,3.636-2.422,5.844-2.422s4.284,0.86,5.845,2.422c1.562,1.562,2.422,3.638,2.422,5.845s-0.859,4.283-2.422,5.846c-1.562,1.562-3.636,2.42-5.845,2.42S7.981,19.672,6.422,18.111z"></path>
                  </g>
                </svg>
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="search your product"
              onChange={(e)=>{searchresult(e.target.value)}}
            />
            <div className="input-group-append">
              <span className="input-group-text close-searchbar">
                <svg
                  viewBox="0 0 329.26933 329"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path>
                </svg>
              </span>
            </div>
          </div>
        
        </div>
      </div>
      {pagename == "/cart" || pagename == "/checkout" || pagename == "/pay" ? "" : <div className="category-header7">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="category-contain">
                <div className="category-left showandhide ">
                  <div className="header-category">
                    <a className="category-toggle">
                      <i className="fa fa-bars" />
                      pages
                    </a>
                    <ul
                      id="main-menu"
                      className={
                        showsidebar == true
                          ? "collapse-category show sm pixelstrap sm-horizontal open"
                          : "collapse-category show sm pixelstrap sm-horizontal"
                      }
                    >
                      <li
                        className="back-btn"
                        onClick={() => setshowsidebar(false)}
                      >
                        <i className="fa fa-angle-left" /> back
                      </li>

                      {categories2.map((item, index) => (
 <MobileSubcategorynew value={item} setwdith={item.child.length > 0 ? 1 : 0}  /> 
                        ))}

{/* {!tokenvalue ? '' : tokenvalue.msg == "Voucher login" ? "": <li>
    <button
          className="btn dark-menu-item desgin1"
          style={{ padding: "6px 18px 0px 18px",top:'20px' }}
          //  to="#"
          onClick={() => {nvg('/mycredit')}}
          type="button"
        >
          <span 
           className="largefont"> my credit </span>
        </button>
    </li>}
    {!tokenvalue ? '' : tokenvalue.msg == "Voucher login" ? "" : <li>
    <button
          className="btn dark-menu-item desgin1"
          style={{ padding: "6px 18px 0px 18px",top:'40px' }}
          //  to="#"
          onClick={() => {
            nvg('/voucher-request')
            // transfer(value.category_id,value.category_name);
          }}
          type="button"
        >
          <span 
           className="largefont">Voucher Request </span>
        </button>
    </li>} */}
                    </ul>
                  </div>
                  <div className="logo-block">
                    <div className="brand-logo logo-sm-center">
                      <NavLink to="/home">
                        <img
                          src={`${process.env.REACT_APP_API_PUBLIC_URL}/images/logo.png`}
                          className="img-fluid"
                          alt="logo"
                        />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="category-right ">
                  <div className="menu-block">
                    <nav id="main-nav">
                      <div className="toggle-nav">
                        <i className="fa fa-bars sidebar-bar" />
                      </div>
                      <ul
                        id="main-menu"
                        className="sm pixelstrap sm-horizontal"
                      >
                        <li>
                          <div className="mobile-back text-right">
                            Back
                            <i
                              className="fa fa-angle-right ps-2"
                              aria-hidden="true"
                            />
                          </div>
                        </li>
                        {categories2.map((item, index) => (
                         <Subcategorynew value={item} setwdith={item.child.length > 0 ? 1 : 0}  /> 
                        ))}
                        {/* {categories.map((item, index) => (
                          item.name == "Electronics" ? <Subcategory value={item} setwdith={0}  /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name == "Appliances" ? <Subcategory value={item}  setwdith={1} /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name == "Apparels" ? <Subcategory value={item} setwdith={1}  /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name == "Accessories" ? <Subcategory value={item} setwdith={0} /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name == "Kitchenware" ? <Subcategory value={item} setwdith={1} /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name == "Sanitaryware" ? <Subcategory value={item} setwdith={0}  /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name == "Pots" ? <Subcategory value={item} setwdith={0}  /> : ''
                        ))}
                        {categories.map((item, index) => (
                          item.name !== "Appliances" && item.name !== "Electronics" && item.name !== "Apparels" && item.name !== "Accessories" && item.name !== "Kitchenware" && item.name !== "Sanitaryware" && item.name !== "Pots" ? <Subcategory value={item} setwdith={item.name == "Furnishing" || item.name == "Interior" ? 0 : 0}  /> : ''
                        ))} */}
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="store-locator"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="searchbar-input ajax-search the-basics">
          <div className="input-group">
            <span className="input-group-text">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="28.931px"
                height="28.932px"
                viewBox="0 0 28.931 28.932"
                style={{ enableBackground: "new 0 0 28.931 28.932" }}
                xmlSpace="preserve"
              >
                <g>
                  <path d="M28.344,25.518l-6.114-6.115c1.486-2.067,2.303-4.537,2.303-7.137c0-3.275-1.275-6.355-3.594-8.672C18.625,1.278,15.543,0,12.266,0C8.99,0,5.909,1.275,3.593,3.594C1.277,5.909,0.001,8.99,0.001,12.266c0,3.276,1.275,6.356,3.592,8.674c2.316,2.316,5.396,3.594,8.673,3.594c2.599,0,5.067-0.813,7.136-2.303l6.114,6.115c0.392,0.391,0.902,0.586,1.414,0.586c0.513,0,1.024-0.195,1.414-0.586C29.125,27.564,29.125,26.299,28.344,25.518z M6.422,18.111c-1.562-1.562-2.421-3.639-2.421-5.846S4.86,7.983,6.422,6.421c1.561-1.562,3.636-2.422,5.844-2.422s4.284,0.86,5.845,2.422c1.562,1.562,2.422,3.638,2.422,5.845s-0.859,4.283-2.422,5.846c-1.562,1.562-3.636,2.42-5.845,2.42S7.981,19.672,6.422,18.111z"></path>
                </g>
              </svg>
            </span>
            <input
              type="search"
              className="form-control typeahead"
              placeholder="Search a Product"
            />
            <span className="input-group-text close-searchbar">
              <svg
                viewBox="0 0 329.26933 329"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path>
              </svg>
            </span>
          </div>
        </div>
      </div>}
      {pagename == "/home" ? <div className="header7  bottomdgn">
        <div className="custom-container">
          <div className="row">  <div className="col-12">
              <div
                className="header-contain"
                style={{ padding: "8px 0px 0px 0px" }}
              >
                <div className="icon-block" style={{ width: "100%" }}>
                  <ul
                    className="theme-color"
                    style={{
                      width: "100%",
                      background: "white",
                      justifyContent: "space-around",
                    }}
                  >
                    <li className=" icon-md-block" onClick={()=>redirectfun('/home')}>
                      <img
                        src="./images/mega-store/brand/home.png"
                        className="newwidthpro"
                        alt="home"
                      />
                      <label
                        htmlFor=""
                        style={{ fontSize: "10px", margin: "0px" }}
                      >
                        Home
                      </label>
                    </li>
                    <li
                      className="mobile-user icon-desk-none"
                      onClick={()=>profilepage(1)}
                    >
                      <img
                        src="./images/mega-store/brand/user.png"
                        className="newwidthpro"
                        alt="profile"
                      />
                      <label
                        htmlFor=""
                        style={{ fontSize: "10px", margin: "0px" }}
                      >
                        Profile
                      </label>
                    </li>
                    <li className="mobile-setting "  onClick={()=>redirectfun('/order-history')} >
                      <img
                        src="./images/mega-store/brand/bag.png"
                        className="newwidthpro"
                        alt="order"
                      />
                      <label
                        htmlFor=""
                        style={{ fontSize: "10px", margin: "0px" }}
                      >
                        Orders
                      </label>
                    </li>
                    <li
                      className="mobile-wishlist item-count icon-desk-none"
                      onClick={()=>redirectfun('/wishlist')}
                    >
                      <img
                        src="./images/mega-store/brand/heart.png"
                        className="newwidthpro"
                        alt="heart"
                      />
                      <label
                        htmlFor=""
                        style={{ fontSize: "10px", margin: "0px" }}
                      >
                        Wishlist
                      </label>
                      <div className="item-count-contain inverce"> {gobalvariable.wishlist} </div>
                    </li>
                    <li
                      className="mobile-cart
                      item-count"
                    onClick={()=>redirectfun('/cart')}
                    >
                      <img
                        src="./images/mega-store/brand/shopping-cart.png"
                        className="newwidthpro"
                        alt="cart"
                      />
                      <label
                        htmlFor=""
                        style={{ fontSize: "10px", margin: "0px" }}
                      >
                        Cart
                      </label>

                      <div className="item-count-contain inverce"> {gobalvariable.cart} </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div> : ""}

      <div
        className="modal fade"
        id="exampleModaltwo"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        // style={{ zIndex: 9383838 }}
        style={{ zIndex: 1 }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl" style={{position:'relative',top:'51px',alignItems:'flex-start'}}>
          <div className="modal-content" style={{flexDirection:'row-reverse'}}>
            <div className="modal-header mod-line">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body"><input type="text" name="" placeholder="Search For Product"  
              // onChange={(e)=>{searchresult(e.target.value)}}
              value={serchvalue}
                      onChange={(e)=>{searchresult(e.target.value);setserchvalue(e.target.value);setshowrecords(e.target.value)}}
                      // onBlur={()=>{emptysearch()}}
 style={{width:'100%',border:'none',outline:'none'}} id="" /></div>
  <ul className="serachlisting" style={{top:'58px',zIndex:999999999,display:serchvalue.length > 2 ? "block" : 'none'}}  >
                  {searchdata[0]?.title ? searchdata.map((item, index) => ( 
                    <li>
                      <div className="p-1 d-flex" style={{gap:"10px",cursor:'pointer'}} onClick={()=>{transfer(item.id,item.title)}} >
                        <div className="">
                        <img src={`${process.env.REACT_APP_API_URL}media/${item.image[0]?.original}`} style={{width:'70px'}} className="  " alt="product" />
                        </div>
                        <div className="cartinfo">
                         <h6 style={{fontSize:'12px',color:'#230bb3',fontWeight:'600',padding:'3px 0px'}}>{item.title}</h6>
                         <h6 style={{fontSize:'12px',color:'#230bb3',padding:'3px 0px'}}>₹{parseInt(item.stockrecords__price)}</h6>
                         {/* <h6 style={{fontSize:'12px',color:'#230bb3',padding:'3px 0px'}}>₹{item.price}</h6> */}
                         <p style={{fontSize:'11px',color:'#230bb3'}}>{extractText(item.description)}</p>

                         {/* <p style={{fontSize:'12px',color:'#230bb3'}} dangerouslySetInnerHTML={{__html:item.description}}></p> */}
                        </div>
                      </div>
                    </li>
                        )) : ''}
                  </ul>
          </div>
         
        </div>
      </div>
    </header> 
  );
};

export default Header;
