import Footer from "../components/Footer";
import Loginheader from "../components/Loginheader";
import "../css/login.css";
import React, { useEffect, useRef, useState } from "react";
import { Field, Form, Formik } from "formik";
import { Clientlogin } from "../Validation/Clientlogin";
import { useLocation, useNavigate } from "react-router-dom";
import { Voucherlogin } from "../Validation/Voucherlogin";
import axios from "axios";
import { gettoken, tokenstore, tokenvoucher } from "../Localstorage/Store";
import Loader from "../components/Loader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
const Clientloginpage = () => {

const ctl = useRef(null)
const vtl = useRef(null)
const nvg = useNavigate()
const [rememberMe, setRememberMe] = useState(false);

const [clienterror,setclienterror] = useState(0)
const [verifystatus,setverifystatus] = useState(0)
const [verifyotpstatus,setverifyotpstatus] = useState(0)
const [hidesend,sethidesend] = useState(0)

const [showotp,setshowotp] = useState(false)
const [showmobile,setshowmobile] = useState(false)
const [loading,setloading] = useState(false)
const [clientloginerrors,setclientloginerrors] = useState({})
const [clientloginsuccess,setclientloginsuccess] = useState({})
const [changepss, setchangepss] = useState(false);
const [forgotpassworderror,setforgotpassworderror] = useState(null)
const [voucherloginerror,setvoucherloginerror] = useState(null)
const [email,setemail] = useState(null)
const [password,setpassword] = useState(null)
const location = useLocation()

console.log("this is login page",location)
const tokenvalue = gettoken();
const userAgent = navigator.userAgent;

  const clientlogin = () =>{
    ctl.current.click()
  }
  const voucherlogin = () =>{
    vtl.current.click()
  }
 

  // client login api start here 
  const loginform = async (val) =>{
   setloading(true)
  const formdata = new FormData()
    formdata.append('email',val.email)
    formdata.append('password',val.password)
    formdata.append('logintype','client')

    try {
      let url = `${process.env.REACT_APP_API_URL}useraccount/LoginView/`;
    const response = await axios.post(url, formdata);
    tokenstore(response.data)
    if (rememberMe) {
      localStorage.setItem('remeberme', JSON.stringify({ email:val.email, password:val.password,logintype:'client' }));
    }
    nvg('/home')
    window.location.reload();
    setloading(false)
    } catch (error) {
      setclientloginerrors(error.response.data)
    setloading(false)
    }
  }



  // client login api end here 




// verify voucher code api start here 

  const verifycode = async (value) =>{
setloading(true)
const formdata = new FormData()
  formdata.append('code',value)

  try {
    let url = `${process.env.REACT_APP_API_URL}useraccount/vouchercodeverify/`;
  const response = await axios.post(url, formdata);
  if(response.data.message == "voucher is valid"){
    setshowmobile(true)
    setverifystatus(1)
  }else{
    setverifystatus(2)
  }
  setloading(false)
  } catch (error) {
      setverifystatus(2)
  setloading(false)
  }
  }
// verify voucher code api end here 




// Send Mobile otp api start here 


const sendotp = async (value) =>{

setloading(true)
const formdata = new FormData()
  formdata.append('mobile_number',value)

  try {
    let url = `${process.env.REACT_APP_API_URL}useraccount/SentOtpForMobile/`;
  const response = await axios.post(url, formdata);
  setshowotp(true)
setverifyotpstatus(1)
sethidesend(1)

setTimeout(() => {
setverifyotpstatus(0)
}, 5000);
  setloading(false)
  } catch (error) {
  setloading(false)
  }
}
// Send Mobile otp api end here 





// voucherform register form start here 

const voucherform = async (val)=>{
  setloading(true)
  const formdata = new FormData()
    formdata.append('voucher',val.vouchercode.trim())
    formdata.append('mobile_number',val.mobile)
    formdata.append('otp',val.otp)
    formdata.append('logintype','customer')
  const voucherdata = {
    'voucher':val.vouchercode.trim(),
    'mobile_number':val.mobile,
    'otp':val.otp,
    'logintype':'customer'
  }
    try {
      let url = `${process.env.REACT_APP_API_URL}useraccount/VerifyRegistrations/`;
    const response = await axios.post(url, formdata);
    if(response.data.message == 'otp verify'){
      {console.log("current voucer value",voucherdata)}
    let url2 = `${process.env.REACT_APP_API_URL}useraccount/CustomerLoginView/`;
    const response2 = await axios.post(url2, voucherdata);

console.log("reees so vouchr is",response2)
if(response2.data.detail){
  setvoucherloginerror(response2.data.detail);
    setTimeout(() => {
      setvoucherloginerror(null);
    }, 8000);
      console.log("reees so vouchrfff is",response2.data)
    }else{
      tokenstore(response2.data)
      tokenvoucher(val.vouchercode.trim())
      nvg('/home', { state: { id: response2.data.login_left } })
      window.location.reload();
      setloading(false)
    }

    }else{
    setvoucherloginerror(response.data.message);
    setTimeout(() => {
      setvoucherloginerror(null);
    }, 8000);
    }
    setloading(false)
    } catch (error) {
      setvoucherloginerror(error.response.data)
    setloading(false)
    }

}

// voucherform register form end here 

const emailInputRef = useRef(null);
const handleMenuClick = () => {
  // Focus on the email input when the menu is clicked
  if (emailInputRef.current) {
    emailInputRef.current.focus();
  }
};



const [userDatas,setdatas]= useState ([])

  const forgotpassword = async(email,emailerror,error) => {
    console.log("emailcheck",email)
    if(email.email == ""){
      if (emailInputRef.current) {
        emailInputRef.current.focus();
      }
      emailerror("email","Email is required")
      
    }else{
      if(error.email){
      console.log("check-again",error)
      }else{
        const formdata = new FormData()
        formdata.append('email',email.email)
      
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}useraccount/forgot-password/`,formdata);
          console.log(response.data);
          setdatas(response.data);
          setclientloginsuccess({status:true,msg : response.data.detail})
          setclientloginerrors({})
          setTimeout(()=>{setclientloginsuccess({})},7000)
        }catch(error){
          console.log("error-email",error.response.data)
          setclientloginerrors({status:false,msg : error.response.data.detail})
          setTimeout(()=>{setclientloginsuccess({})},7000)
          //  setforgotpassworderror(error.response.data)
        }
      }
      

    }
    
    console.log("agincheck",email,emailerror,error)
  
  }
  

useEffect(() => {


  if(location?.state?.id == 1){

    vtl.current.click()
  }
  // Check if there's stored user data in localStorage
  const userData = localStorage.getItem('userData');
  if (userData) {
    const { email, password } = JSON.parse(userData);
    setemail(email);
    setpassword(password);
    // Perform automatic login with stored credentials
    // Your login logic here
    // ...
  }

//  .....forgot password api....








}, []);


  return (
    <>
 
 {/* {location.pathname == "/clientlogin" ? "" : <Loginheader loginchange={clientlogin} voucherchange={voucherlogin} focusemail={handleMenuClick} />} */}
      
      
      <div className={loading == true ? "d-flex align-items-center justify-content-center" : "d-none"} style={{position:"fixed",width:'100vw',height:'100vh'}}>  {loading == true ? <Loader /> : ''}</div>
   
      {/*header end*/}
      <section className="login-page bg-body" style={{width:'100%',height:'98vh'}}>
        <div className="container" style={{background:'white',marginTop:'50px',paddingBottom:'51px',height:'100%'}}>
        
          <div className="row align-items-center g-5 pt-1" style={{height:'100%'}}>
            <div
              className="col-10 col-sm-8 col-lg-6"
              style={{ margin: "0 auto" }}
            >
              <img
                src="./images/login-img.png"
                className="d-block mx-lg-auto img-fluid"
                alt="img"
                width={500}
                height={500}
                loading="lazy"
              />
            </div>


            {/* <div className="col-lg-6" style={{overflowY:"clip"}}> */}
            <div className="col-lg-6">
              <div className="container newpadding hidepadding">
                <div className="card p-1 " style={{overflow:'hidden',border:'none'}} >
                  <img
                  onClick={()=>{nvg('/')}}
                    src="./images/logo.png"
                    className="login-tabs-img"
                    style={{marginBottom:'0px',cursor:'pointer',maxWidth:'320px'}}
                    alt
                  />
                  <nav  style={{ justifyContent: "center",position:'absolute',top:'-9000px' }}>
                    <div
                      className="nav nav-tabs "
                      style={{ justifyContent: "center",position:'absolute',top:'-9000px' }}
                      id="nav-tab"
                      role="tablist"
                    >
                      <button
                        className="nav-link active"
                        id="nav-home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-home"
                        type="button"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                        ref={ctl}
                        hidden
                      >
                        Client Login
                      </button>
                      <button
                        className="nav-link"
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-profile"
                        type="button"
                        role="tab"
                        ref={vtl}
                        aria-controls="nav-profile"
                        aria-selected="false"
                      >
                        Voucher Redemption
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content p-2 " id="nav-tabContent">
                  <div
                      className="tab-pane fade active show"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                       <div className="album py-1">
                  <Formik
              initialValues={{
                email: email == null ? "" : email,
                password: password == null ? "" : password,
              }}
              validationSchema={Clientlogin}
              onSubmit={(values) => {
                loginform(values)
              }}
            >

{({
                values,
                errors,
                handleSubmit,
                touched,
                setFieldValue,setFieldError,
              }) => (
                <Form autoComplete="off" className="form-login" onSubmit={handleSubmit}>


                     
                        {/* <form action className="form-login"> */}
                          <label htmlFor="text">Account Id</label>
                          {/* <input
                            type="text"
                            className="login-input"
                            id="input1"
                            placeholder="Enter your Email Id"
                            value={email}
                            onChange={(e)=>setemail(e.target.value)}
                          /> */}

<Field
                            type="text"
                            name="email"
                            innerRef={emailInputRef}
                            className="login-input mb-0"
                            placeholder="Enter Your Email/Account ID"
                            value={values.email}
                          />
                          <span style={{display:"block",marginBottom:"20px"}}> {errors.email && touched.email ? (
                            <p style={{ color: "red" }}>
                              {errors.email}
                            </p>
                          ) : null}</span>
                          <label htmlFor="text">Password</label>
                          {/* <input
                            type="password"
                            id="input2"
                            className="login-input"
                            placeholder="Enter your Password"
                            value={password}
                            onChange={(e)=>setpassword(e.target.value)}
                          /> */}
                           {/* <Field
                            type="password"
                            name="password"
                            className="login-input mb-0"
                            placeholder="Enter your password"
                            value={values.password}
                          />
                          <span style={{display:'block',marginBottom:"20px"}}> {errors.password && touched.password ? (
                            <p style={{ color: "red" }}>
                              {errors.password}
                            </p>
                          ) : null}</span> */}
                           <div style={{position:'relative'}}>
                           <Field
                                type={changepss == false ? "password" : "text"}
                                name="password"
                                className="login-input mb-0"
                                placeholder="Enter Your Password"
                                value={values.password}
                              />

                              <span
                                className=""
                                style={{display:userAgent.indexOf('Edg') == -1 ? 'block' : 'none'}}
                                onClick={() => {
                                  setchangepss(!changepss);
                                }}
                              >
                                {console.log("first",userAgent)}
                                {changepss == false ? (
                                  // <i className="fa fa-eye-slash"></i>
                                  <div className="eyestyle2" 
                                style={{display:userAgent.indexOf('Edg') == -1 ? 'block' : 'none'}}
                                ><AiOutlineEyeInvisible /></div>

                                ) : (
                                  <i style={{display:userAgent.indexOf('Edg') == -1 ? 'block' : 'none'}} className="fa fa-solid fa-eye   eyestyle2" > </i>
                                )}
                              </span>
                              </div>



                          <div className="login-sub">
                            <div className="remember">
                              <input
                                className="form-check-input m-2"
                                type="checkbox"
                                defaultValue
                                id="flexCheckDefault"
                                checked={rememberMe} onChange={() => setRememberMe(!rememberMe)}
                              />
                              <p style={{color:'black'}}>Remember Me</p>
                            </div>
                            <div>
                              <p onClick={()=>{forgotpassword(values,setFieldError,errors)}} style={{color:'black',cursor:'pointer'}}>{forgotpassworderror ? forgotpassworderror.msg : ''} Forgot Password?</p>
                            </div>
                          </div>
                       
                          {/* <button style={{background:errors.email || errors.password ? '#efefef' : '',color:errors.email || errors.password ? '#230bb3' : ''}} type="submit" disabled className="btn" id="myForm"> */}
                        {errors.email || errors.password ? <button type="submit" disabled className="btn" id="myForm">
                            login
                          </button> : <button  type="submit"  className="btn" id="myForm">
                            login
                          </button>}
                          <span style={{color:"red",textAlign:"center",display:'block',marginTop:'3px',fontSize:'15px'}}>{clientloginerrors ? clientloginerrors.msg : ''}</span>
                          <span style={{color:"green",textAlign:"center",display:'block',marginTop:'3px',fontSize:'15px'}}>{clientloginsuccess ? clientloginsuccess.msg : ''}</span>
                          <div className="sign-up">
                            <p >
                              {/* Don't have an account? <b style={{cursor:'pointer'}} onClick={()=>{nvg('/contact')}}>Contact Us</b> */}
                               <b style={{cursor:'pointer'}} onClick={()=>{nvg('/voucherlogin',{ state: { id: 1} })}}>Are You Here For Voucher Redemption?</b>
                            </p>
                          </div>
                        {/* </form> */}

                  </Form>
              )}
            </Formik>
              </div>
              </div>

                  
                    <div
                      className="tab-pane fade"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <div className="album py-1">


                      <Formik
              initialValues={{
                mobile: "",
                vouchercode: "",
              }}
              validationSchema={Voucherlogin}
              onSubmit={(values) => {
                voucherform(values)
              }}
            >

{({
                values,
                errors,
                handleSubmit,
                touched,
                setFieldValue,
              }) => (
                <Form autoComplete="off" className="form-login" onSubmit={handleSubmit}>
                        {/* <form action className="form-login"> */}
                          <label htmlFor="text">Voucher Code</label>

                          {/* <input
                            type="text"
                            className="login-input"
                            id="input1"
                            placeholder="Enter your Voucher Code"
                          /> */}
                                 <div className="log-sendotp">
                            <Field
                            type="text"
                            name="vouchercode"
                            className="login-input mb-0"
                            placeholder="Enter Your Voucher Code"
                            style={{paddingRight:'50px'}}
                            value={values.vouchercode}
                            
                          />
                            <span style={{cursor:'pointer'}} onClick={()=>{verifycode(values.vouchercode)}}>{values.vouchercode == "" ? "" : verifystatus == 0 ? "Verify" : verifystatus == 1 ? <img src="./images/sucess.png" alt="404" /> : 'Verify' } </span>
                          </div>



                          
                          <span style={{display:'block',marginBottom:"10px"}}> {verifystatus == 2 && errors.vouchercode !== "" && touched.vouchercode !== "" ? (
                            <p style={{ color: "red" }}>
                              Voucher Code does not exist <img src="./images/question.png" alt="404" />
                            </p>
                          ) : null}</span>
                          <span style={{display:'block',marginBottom:"10px"}}> {verifystatus == 2 ? "" : errors.vouchercode && touched.vouchercode ? (
                            <p style={{ color: "red" }}>
                              {errors.vouchercode}
                            </p>
                          ) : null}</span>

               {showmobile == true ? <label htmlFor="text">Mobile No.</label> : ""}
               {showmobile == true ? <div className="log-sendotp">
                            {/* <input
                              type="text"
                              className="login-input"
                              id="input2"
                              placeholder="Enter your Mobile No."
                            /> */}
                            <Field
                            type="number"
                            name="mobile"
                            className="login-input mb-0"
                            placeholder="Enter Your Mobile No"
                            value={values.mobile}
                          />
                            {/* <span style={{cursor:'pointer',backgroundColor:"white"}} onClick={()=>{sendotp(values.mobile)}}>{hidesend == 1 ? "" : values.mobile == "" ? "" : errors.mobile ? "" : "Send OTP"}</span> */}
                            <span style={{cursor:'pointer',backgroundColor:"white"}} onClick={()=>{sendotp(values.mobile)}}>{ values.mobile == "" ? "" : errors.mobile ? "" : "Send OTP"}</span>
                          </div> : ""}



                          {verifyotpstatus == 1 ? <span style={{display:'block',marginBottom:"20px"}}> 
                            <p style={{ color: "green" }}>
                              OTP send to your Mobile Number
                            </p>
                         </span> : ''}
                          
                         {showmobile == true ? <span style={{display:'block',marginBottom:"20px"}}> {errors.mobile && touched.mobile ? (
                            <p style={{ color: "red" }}>
                              {errors.mobile}
                            </p>
                          ) : null}</span> : ""}


                         
                         {showotp == true ? <label htmlFor="text">OTP No.</label> : ''} 
                         {showotp == true ? 
                         <Field
                         type="number"
                         name="otp"
                         
                         className="login-input mb-0"
                         placeholder="Enter Your OTP"
                         value={values.otp}
                       /> : ''} 
                       <span style={{display:'block',marginBottom:"20px"}}> {errors.otp && touched.otp ? (
                         <p style={{ color: "red" }}>
                           {errors.otp}
                         </p>
                       ) : null}</span>
                       {errors.otp || errors.mobile || values.mobile == "" ? <button type="submit" disabled className="btn" id="myForm">
                            login
                          </button> : <button  type="submit"  className="btn" id="myForm">
                            login
                          </button>}
                          {/* <button style={{background:errors.mobile || errors.otp ? '#efefef' : '',color:errors.mobile || errors.otp ? '#230bb3' : ''}} type="submit"  className="btn" id="myForm">
                            login
                          </button> */}
                          <span style={{color:"red",textAlign:"center",display:'block',marginTop:'3px',fontSize:'15px',textTransform:'capitalize'}}>{voucherloginerror ? voucherloginerror : ''}</span>
                          <div className="sign-up">
                            <p>
                              Don't have an account? <b style={{cursor:'pointer'}} onClick={()=>{nvg('/contact')}}>Contact Us</b>
                            </p>
                          </div>
                        {/* </form> */}

                        </Form>
              )}
            </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section style={{marginTop:'50px'}}>
        <div className="container">
          <div className="row">
            <h2 className="login-about customline3">
              About One<span className="ab-1">Up</span>
              <span className="ab-2">Brands</span>
            </h2>
            <p className="login-para">
              Lorem ipsum dolor sit amet consectetur. Sit augue maecenas mattis
              senectus quam eu. Aenean consequat pretium vitae egestas turpis
              aliquam. Egestas et porttitor vitae volutpat aliquet netus
              volutpat. Netus auctor dui mattis vulputate posuere sagittis
              fermentum convallis. Porta cras risus quis dictumst velit.
              Interdum amet accumsan nullam porta facilisi. Malesuada tincidunt
              tempor venenatis duis. Et enim enim dictum varius consequat nunc
              condimentum. Id eu viverra purus vestibulum accumsan rhoncus
              tincidunt commodo. Ornare porttitor sit ornare consequat sed nunc
              aliquam leo sollicitudin. Proin sapien arcu nunc duis. Enim quam
              velit gravida feugiat consequat purus nam ultrices. Viverra mollis
              volutpat in consectetur venenatis sodales ut placerat dis.
            </p>
          </div>
        </div>
      </section> */}
      {/* footer start */}
      {/* <Loginfooter focusemail={handleMenuClick} /> */}
      {/* <Footer /> */}
      {/* footer end */}
    </>
  );
};
export default Clientloginpage;
