import React, { useCallback, useEffect, useState } from "react";
import Header from "../components/Header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { gettoken, getvoucher } from "../Localstorage/Store";
import MobileSubcategory from "../components/MobileSubcategory";
import ReactPaginate from "react-paginate";
import Loader from "../components/Loader";
import ReactSlider from 'react-slider'
import { FaSearch } from 'react-icons/fa';

const Category = () => {
  const { id, name, url } = useParams();
  // console.log("params", id, name, url);
  const location = useLocation();
  const nvg = useNavigate();
  const tokenvalue = gettoken();
  // category filter start here
  const [pricevalue, setpricevalue] = useState(0);
  const [fixminprice, setfixminprice] = useState(0);
  const [fixmaxprice, setfixmaxprice] = useState(0);
  const [fixminpriceval, setfixminpriceval] = useState(0);
  const [fixmaxpriceval, setfixmaxpriceval] = useState(0);
  // console.log("min price",fixminprice,"max price here 2",fixmaxprice);
  const [attribute, setattribute] = useState({
    min_price: 0,
    sort_by: "",
  });
  const [showattr, setshowattr] = useState({});
  // console.log("first ppppp",attribute,"lllkkdkdkd",showattr);
  const [brand, setbrand] = useState(true);
  const [price, setprice] = useState(true);
  const [pricevlu, setpricevlu] = useState(false);
  const [pricetxt, setpricetxt] = useState("");
  const [gender, setgender] = useState(false);
  const [gendervlu, setgendervlu] = useState(false);
  const [gendertxt, setgendertxt] = useState("");
  const [discount, setdiscount] = useState(false);
  const [discountvlu, setdiscountvlu] = useState(false);
  const [discounttxt, setdiscounttxt] = useState("");
  const [showpages, setshowpages] = useState(false);

  const [filter, setfilter] = useState(false);
  const [currentwdith, setcurrentwdith] = useState(window.innerWidth);
  const [showsidebar, setshowsidebar] = useState(false);
  const [sortby, setsortby] = useState(false);
  const [subcategory, setsubcategory] = useState(false);
  const [acdropdown, setacdropdown] = useState(false);
  const [loading, setloading] = useState(true);
  const [data, setData] = useState([]);
  const [data4, setData4] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setpageSize] = useState(40);
  const [categroyone, setcategroyone] = useState(null);
  const [categorytwo, setcategorytwo] = useState(null);
  // let pageSize = 12;
  // let pageSize = 12;
  const localvouhcer = getvoucher();

  console.log("this is a good voucher is here",localvouhcer)
  // test my case
  console.log(data, "products___________")
  console.log(data4, "data4___________")
  const [showAll, setShowAll] = useState({});
  const [showAlltwo, setShowAlltwo] = useState({});


  const handleToggleShowAll = (name) => {
    setShowAll(prevState => ({ ...prevState, [name]: !prevState[name] }));
  };

  const handleToggleShowAlltwo = (name) => {
    setShowAlltwo(prevState => ({ ...prevState, [name]: !prevState[name] }));
  };

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredValues = (values) => {
    return values.filter((value) =>
      value.value_option__option.toLowerCase().includes(searchTerm)
    );
  };




  const [filterQueries, setFilterQueries] = useState({});

  // Handle search input change
  const handleFilterChange = (itemName, value) => {
    setFilterQueries(prevState => ({
      ...prevState,
      [itemName]: value,
    }));
  };

  // Filter items based on search query
  const getFilteredValues = (item) => {
    const query = filterQueries[item.name] || '';
    if (!query) return item.values;
    return item.values.filter(item2 => item2.value_option__option.toLowerCase().includes(query.toLowerCase()));
  };
  // test my case
  // console.log("data  of by category is here",fixmaxpriceval,fixminpriceval,fixmaxprice,fixminprice)
  const transfer = (productid, pname) => {
    nvg("/productdetails", {
      state: {
        id: productid,
        // categoryid: id,
        // pagename: name,
        pname: pname,
        // categoryid: location.state?.id,
        // pagename: location.state?.pagename,
      },
    });
    window.location.reload();
  };

  const updateDataValue2 = (key, newData) => {
    setshowattr((prevData) => {
      const updatedData = { ...prevData, [key]: newData };
      return updatedData;
    });
  };




  const removefilter = () => {

    if (url != "none") {
      nvg(`/category/${id}/${name}/none`);
      window.location.reload();
    }
  }




  const mergeValues = (queryString) => {
    const params = new URLSearchParams(queryString);

    const mergedParams = Array.from(params.entries()).reduce((acc, [name, value]) => {
      acc[name] = acc[name] || new Set();
      acc[name].add(value);
      return acc;
    }, {});

    const mergedQueryString = Object.entries(mergedParams).map(([name, values]) => {
      return `&${name}=${Array.from(values).join(',')}`;
    }).join('');

    return mergedQueryString;
  };


  const updateDataValueforsort = (key, newData) => {
    let finalurl;
    let finalurl2;
    let exists = url.includes(`${key}=`);
    if (key == "max_price") {
      if (exists) {
        const sortByRegex = new RegExp(`${key}=[^&]+`);
        const sortByRegex2 = new RegExp(`min_price=[^&]+`);
        finalurl = url.replace(sortByRegex, `${key}=${newData[1]}`);
        finalurl2 = finalurl.replace(sortByRegex2, `min_price=${newData[0]}`);

        nvg(`/category/${id}/${name}/${finalurl2}`);
        window.location.reload();
      } else {
        if (url == "none") {
          finalurl = `&${key}=${newData[1]}&min_price=${newData[0]}`;
        } else {
          finalurl = url + `&${key}=${newData[1]}&min_price=${newData[0]}`;
        }
        nvg(`/category/${id}/${name}/${finalurl}`);
        window.location.reload();
      }
    } else {
      if (exists) {
        if (url == `${key}=${newData}`) {
          finalurl = "none";
        } else {
          const sortByRegex = new RegExp(`${key}=[^&]+`);
          // finalurl = url.replace(`${key}=${newData}`,"");
          finalurl = url.replace(sortByRegex, `${key}=${newData}`);

        }
        nvg(`/category/${id}/${name}/${finalurl}`);
        window.location.reload();
      } else {
        if (url == "none") {
          finalurl = `&${key}=${newData}`;
        } else {
          finalurl = url + `&${key}=${newData}`;
        }
        nvg(`/category/${id}/${name}/${finalurl}`);
        window.location.reload();
      }
    }


  };
  const updateDataValue = (key, newData) => {
    let finalurl;
    let exists;
    if (key == "min_price" || key == "max_price" || key == "sort_by" || key == "offset") {
      exists = url.includes(`${key}=${newData}`);
      if (exists) {
        if (url == `${key}=${newData}`) {
          finalurl = "none";
        } else {

          finalurl = url.replace(`${key}=${newData}`, "");
        }
        nvg(`/category/${id}/${name}/${finalurl}`);
        window.location.reload();
      } else {
        if (url == "none") {
          finalurl = `${key}=${newData}`;
        } else {
          finalurl = url + `&${key}=${newData}`;
        }
        nvg(`/category/${id}/${name}/${finalurl}`);
        window.location.reload();
      }
    } else {
      exists = url.includes(`attr_name_${key}=${key}&attr_value_${key}=${newData}`);
      if (exists) {
        if (url == `&attr_name_${key}=${key}&attr_value_${key}=${newData}`) {
          finalurl = "none";
        } else {
          finalurl = url.replace(`&attr_name_${key}=${key}&attr_value_${key}=${newData}`, "");
        }
        nvg(`/category/${id}/${name}/${finalurl}`);
        window.location.reload();
      } else {
        if (url == "none") {
          finalurl = `&attr_name_${key}=${key}&attr_value_${key}=${newData}`;
        } else {
          finalurl = url + `&attr_name_${key}=${key}&attr_value_${key}=${newData}`;
        }
        nvg(`/category/${id}/${name}/${finalurl}`);
        window.location.reload();
      }
    }
  };

  const filterdata = useCallback(
    (data) => {
      const filterdatainner = async (attr) => {
        try {
          setData([]);
          let urlapi2;
          // let url = `${process.env.REACT_APP_API_URL}homepageapi/attr/${location.state.id}/?${attr}`;
          if (url == "none") {
            urlapi2 = `${process.env.REACT_APP_API_URL}homepageapi/attr/${id}/?sort_by=new_added&offset=${attr}`;
          } else {
            let checkurl = url.includes(`sort_by`);
            if (checkurl) {

              const outputString = mergeValues(url);
              urlapi2 = `${process.env.REACT_APP_API_URL}homepageapi/attr/${id}/?${outputString}&offset=${attr}`;
            } else {
              const outputString = mergeValues(url);
              urlapi2 = `${process.env.REACT_APP_API_URL}homepageapi/attr/${id}/?${outputString}&sort_by=new_added&offset=${attr}`;
            }

          }
          // let url = `${process.env.REACT_APP_API_URL}homepageapi/attr/${location.state.id}/?${attr1.name}=${attr1.value}&${attr2.name}=${attr2.value}&${attr3.name}=${attr3.value}&${attr4.name}=${attr4.value}&${attr5.name}=${attr5.value}&${attr6.name}=${attr6.value}`;
          setloading(true);
          const response = await axios.get(urlapi2);
          // pageSize = 1
          setData([]);
          response.data.results
            ? setData(response.data.results.results)
            : setData([]);
          settotalrecords(response.data.count);
          handlePageClick(1);
          setTimeout(() => {
            setloading(false);
          }, 2000);
        } catch (error) {
          console.log("category error", error);
        }
      };
      filterdatainner(data);
    },
    [data]
  );
  // const filterdata = async (attr) => {
  //   setData([]);
  //   let url = `${process.env.REACT_APP_API_URL}homepageapi/attr/${location.state.id}/?${attr}`;
  //   // let url = `${process.env.REACT_APP_API_URL}homepageapi/attr/${location.state.id}/?${attr1.name}=${attr1.value}&${attr2.name}=${attr2.value}&${attr3.name}=${attr3.value}&${attr4.name}=${attr4.value}&${attr5.name}=${attr5.value}&${attr6.name}=${attr6.value}`;
  //   setloading(true);
  //   const response = await axios.get(url);
  //   // pageSize = 1
  //   setData([])
  //   handlePageClick(1)
  //   setTimeout(() => {
  //     setloading(false);
  //   }, 2000);
  //   response.data?.results ? setData(response.data.results.results) : setData([])
  //   settotalrecords(response.data.count);
  // };



  const getLowestOrderImage = (images) => {
    if (!images || images.length === 0) return null;
    return images.reduce((lowest, image) => {
      return (lowest === null || image.display_order < lowest.display_order) ? image : lowest;
    }, null);
  };
  const [categories, setCategories] = useState([]);
  const [totalrecords, settotalrecords] = useState(0);

  useEffect(() => {
    // if (location.state.id) {

    if (id) {
      async function fetchcategory(id) {
        // setloading(true);
        // let url = `${process.env.REACT_APP_API_URL}homepageapi/CategoryWisedProduct/${id}/`;
        try {


          let regex = /max_price=(\d+)/;
          let regex2 = /min_price=(\d+)/;
          let hasMaxPrice = regex.test(url);
          if (hasMaxPrice) {
            // Extract max_price value using match
            let match = url.match(regex);
            let match2 = url.match(regex2);

            if (match) {
              // setpricevalue(match[1])
              setfixmaxpriceval(match[1])
              setfixminpriceval(match2[1])

              // Now you can use maxPriceValue as needed in your component
            } else {
              // setfixminpriceval(response.data.results.static_min_price);
              // setfixmaxpriceval(response.data.results.static_max_price);
              // setpricevalue(response.data.results.static_max_price)
            }
          } else {
            // setpricevalue(response.data.results.static_max_price)
            // setfixminpriceval(fixminprice);
            //   setfixmaxpriceval(fixmaxprice);
            // setfixminpriceval(response.data.results.static_min_price);
            //   setfixmaxpriceval(response.data.results.static_max_price);
          }
          let urlapi;
          if (url == "none") {
            if(localvouhcer){
              urlapi = `${process.env.REACT_APP_API_URL}homepageapi/attr/${id}/?sort_by=new_added&code_list=${localvouhcer}`;
            }else{
              urlapi = `${process.env.REACT_APP_API_URL}homepageapi/attr/${id}/?sort_by=new_added`;
            }
          } else {
            if (url.includes('sort_by')) {
              const outputString = mergeValues(url);
              if(localvouhcer){
                // urlapi = `${process.env.REACT_APP_API_URL}homepageapi/attr/${id}/?sort_by=new_added&code_list=${localvouhcer}`;
                urlapi = `${process.env.REACT_APP_API_URL}homepageapi/attr/${id}/?code_list=${localvouhcer}&${outputString}`;
              }else{
                urlapi = `${process.env.REACT_APP_API_URL}homepageapi/attr/${id}/?${outputString}`;
              }
            } else {
              const outputString = mergeValues(url);
              if(localvouhcer){
                // urlapi = `${process.env.REACT_APP_API_URL}homepageapi/attr/${id}/?sort_by=new_added&code_list=${localvouhcer}`;
                urlapi = `${process.env.REACT_APP_API_URL}homepageapi/attr/${id}/?code_list=${localvouhcer}&sort_by=new_added${outputString}`;
              }else{
                urlapi = `${process.env.REACT_APP_API_URL}homepageapi/attr/${id}/?sort_by=new_added${outputString}`;
              }
            }
          }
          const response = await axios.get(urlapi);
          // console.log("response of product data of resilu",response)

          settotalrecords(response.data.count);
          setData(response.data.results.results);
          if (response.data.results.results[0]) {
            setshowpages(true)
          }
          // setfixminprice(response.data.results.static_min_price);
          // setfixmaxprice(response.data.results.static_max_price);


          setloading(false);
        } catch (error) {
          setData([])
          setloading(false);

        }

      }

      async function fetchattribute(id) {
        setloading(true);
        let url2 = `${process.env.REACT_APP_API_URL}homepageapi/product-attributes/${id}/`;
        const response = await axios.get(url2);
        // console.log("new data",response);
        setData4(response.data.data);
        setfixminprice(response.data.static_min_price);
        setfixmaxprice(response.data.static_max_price);

        let regex = /max_price=(\d+)/;
        let regex2 = /min_price=(\d+)/;
        let hasMaxPrice = regex.test(url);

        if (!hasMaxPrice) {
          setfixminpriceval(response.data.static_min_price);
          setfixmaxpriceval(response.data.static_max_price);
        }
        // console.log("text freekdkeef",response.data.data[0].static_min_price,"ffkfmax dkkkd",response.data.data[0].static_max_price)
        const updatedAttributes = {};
        response.data.data.forEach((item, index) => {
          updatedAttributes[item.name] = "";
        });
        const updatedAttributes2 = {};
        response.data.data.forEach((item, index) => {
          let finalattr = url.includes(item.name);
          if (finalattr) {
            if (item.name == "brand") {
              updatedAttributes2[item.name] = true;
            } else {
              updatedAttributes2[item.name] = true;
            }
          } else {
            if (item.name == "brand") {
              updatedAttributes2[item.name] = true;
            } else {
              updatedAttributes2[item.name] = true;
            }
          }
        });
        setattribute((prevData) => ({
          ...prevData,
          ...updatedAttributes,
        }));
        setshowattr((prevData) => ({
          ...prevData,
          ...updatedAttributes2,
        }));

        // setloading(false);
      }
      fetchattribute(id);
      fetchcategory(id);
      // fetchcategory(location.state.id);
      // fetchattribute(location.state.id);
    }

    async function fetchcategory() {
      let url = `${process.env.REACT_APP_API_URL}api/categories/`;
      const response = await axios.get(url);
      setCategories(response.data);
    }

    const existbred = name.includes('>');
    if (existbred) {
      // console.log("dkkkdd ajaa",existbred,name)
      const splitbred = name.split('>')
      // console.log("spitbred",splitbred);

      setcategroyone(splitbred[0]);
      console.log("ddkdkdkdd", splitbred[1])
      setcategorytwo(splitbred[1]);
    } else {
      // console.log("dkkkdd ajaa",existbred,name)
      // console.log("spitbred",name);
      setcategroyone(name);
      setcategorytwo('');
    }
    // updateDataValue('sort_by','l2h')
    fetchcategory();
  }, [id, name, url]);

  // Function to slice the data based on the current page and page size
  // const getPaginatedData = () => {
  //   const startIndex = currentPage * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   return data.slice(startIndex, endIndex);
  // };
  const pageCount = Math.ceil(totalrecords / 40);
  // const pageCount = Math.ceil(totalrecords / pageSize);
  const handlePageClick = (selectedPage) => {
    // console.log("eeeeeeeeeeeeeefffffffffff",selectedPage)
    // if(selectedPage == "first"){
    //   setCurrentPage(0);
    // }else{
    //   setCurrentPage(selectedPage.selected);
    // }
  };
  // console.log("new filed in attribute 333", attribute);
  // console.log("new filed in attribute 22334444", showattr);
  // console.log("check data is cheking", data);
  return <>
  <Header />
  {loading == true ? (
   <div style={{width:'100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}>
     <h2
      style={{
        textAlign: "center",
        paddingTop: "15px",
        fontWeight: "600",
        fontSize: "20px",
      }}
    >
      <Loader />
    </h2>
    </div>
  ) : (
    <>
      {/* <Header /> */}

      <div className="category-header7" style={{ zIndex: 9991 }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="category-contain">
                <div className="category-left showandhide ">
                  <div className="header-category">
                    <a className="category-toggle">
                      <i className="fa fa-bars" />
                      pages
                    </a>
                    <ul
                      id="main-menu"
                      className={
                        showsidebar == true
                          ? "collapse-category show sm pixelstrap sm-horizontal open"
                          : "collapse-category show sm pixelstrap sm-horizontal"
                      }
                    >
                      <li
                        className="back-btn"
                        onClick={() => setshowsidebar(false)}
                      >
                        <i className="fa fa-angle-left" /> back
                      </li>

                      {categories.map((item, index) => (
                        <MobileSubcategory value={item} />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="searchbar-input ajax-search the-basics">
          <div className="input-group">
            <span className="input-group-text">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="28.931px"
                height="28.932px"
                viewBox="0 0 28.931 28.932"
                style={{ enableBackground: "new 0 0 28.931 28.932" }}
                xmlSpace="preserve"
              >
                <g>
                  <path d="M28.344,25.518l-6.114-6.115c1.486-2.067,2.303-4.537,2.303-7.137c0-3.275-1.275-6.355-3.594-8.672C18.625,1.278,15.543,0,12.266,0C8.99,0,5.909,1.275,3.593,3.594C1.277,5.909,0.001,8.99,0.001,12.266c0,3.276,1.275,6.356,3.592,8.674c2.316,2.316,5.396,3.594,8.673,3.594c2.599,0,5.067-0.813,7.136-2.303l6.114,6.115c0.392,0.391,0.902,0.586,1.414,0.586c0.513,0,1.024-0.195,1.414-0.586C29.125,27.564,29.125,26.299,28.344,25.518z M6.422,18.111c-1.562-1.562-2.421-3.639-2.421-5.846S4.86,7.983,6.422,6.421c1.561-1.562,3.636-2.422,5.844-2.422s4.284,0.86,5.845,2.422c1.562,1.562,2.422,3.638,2.422,5.845s-0.859,4.283-2.422,5.846c-1.562,1.562-3.636,2.42-5.845,2.42S7.981,19.672,6.422,18.111z"></path>
                </g>
              </svg>
            </span>
            <input
              type="search"
              className="form-control typeahead"
              placeholder="Search a Product"
            />
            <span className="input-group-text close-searchbar">
              <svg
                viewBox="0 0 329.26933 329"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path>
              </svg>
            </span>
          </div>
        </div>
      </div>

      {/* breadcrumb start */}
      <div className="breadcrumb-main marginfromtop breadcrumbpadding">
        <div className="container m-0">
          <div className="row">
            <div className="col">
              <div className="breadcrumb-contain">
                <div>
                  <ul>
                    <li>
                      <a href="/home">home</a>
                    </li>
                    {/* <li style={{fontSize:"12px"}}>&gt;</li>
                <li><a href="javascript:void(0)">Category</a></li> */}
                    <li style={{ fontSize: "12px" }}>&gt;</li>
                    <li>
                      <a href="javascript:void(0)">
                        {name}
                        {/* {location.state?.pagename} */}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumb End */}
      {/* section start */}
      <section
        className="section-big-pt-space ratio_asos b-g-light"
        style={{ padding: "0px", background: '#f9f9f9' }}
      >
        <div className="collection-wrapper" style={{ background: "#f9f9f9" }}>
          <div className="custom-container">
            <div className="row categoryfixclass1" style={{ background: "#f9f9f9" }}>
              <div
                className="col-sm-3 collection-filter category-page-side categoryfixclass2"
                style={{
                  zIndex: currentwdith < 990 ? 9991 : 1,
                  left: "-15px",
                  display:
                    currentwdith < 990
                      ? filter == true
                        ? "block"
                        : "none"
                      : "block",
                  padding: "10px",
                }}
              >
                {/* side-bar colleps block stat */}
                <div className="collection-filter-block creative-card creative-inner category-side">
                  {/* brand filter start */}
                  <div
                    className="collection-mobile-back"
                    onClick={() => {
                      setfilter(!filter);
                      // console.log("click me");
                    }}
                  >
                    <span className="filter-back">
                      <i className="fa fa-angle-left" aria-hidden="true" /> back
                    </span>
                  </div>
                  <div className="collection-collapse-block open">
                    <h4>
                      CATEGORIES
                    </h4>
                    <h6 style={{ color: categorytwo == null ? `black` : `#878787`, padding: `10px 0px 10px 8px` }}>{` ${categroyone}`}</h6>
                    <h6 style={{ color: `#black`, position: 'relative', left: '10px', display: categorytwo == null ? 'none' : 'block', padding: `0 0 10px 8px` }}>{`${categorytwo}`}</h6>
                  </div>






                  {data4.map((item, index) =>
        item.name === 'brand' ? (
          <div
            key={index}
            className="collection-collapse-block open"
            style={{ display: item.values?.[0] ? 'block' : 'none' }}
          >
            <h3
              className={
                item.name.length > 6
                  ? 'collapse-block-title dynamic-after2'
                  : 'collapse-block-title dynamic-after'
              }
              onClick={() => {
                showattr[item.name] === false
                  ? updateDataValue2(item.name, true)
                  : updateDataValue2(item.name, false);
              }}
            >
              {item.name}
              <style>
                {`.dynamic-after::after {
                    left:60px;
                  }  
                  .dynamic-after2::after {
                    left:91px;
                  }`}
              </style>
            </h3>

            <div
              className="collection-collapse-block-content"
              style={{
                display: showattr[item.name] === true ? 'block' : 'none',
              }}
            >
              <div className="collection-brand-filter">
                <div className="lastfilter-search-container">
                  <input
                    className="lastfilter-search__input"
                    type="text"
                    placeholder={`Search ${item.name}`}
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <FaSearch className="lastfilter-search-icon" />
                </div>
                {filteredValues(item.values)
                  .slice(0, showAll[item.name] ? item.values.length : url.includes(`attr_name_${item.name}=${item.name}&attr_value_${item.name}=`) ? 40 : 4)
                  .map((item2, index2) => (
                    <div
                      key={index2}
                      className="custom-control custom-checkbox form-check collection-filter-checkbox"
                    >
                      {!url.includes(
                        `attr_name_${item.name}=${item.name}&attr_value_${item.name}=${item2.value_option__option}`
                      ) ? (
                        <input
                          type="checkbox"
                          className="custom-control-input form-check-input"
                          onClick={() => {
                            setpageSize(0);
                            updateDataValue(
                              item.name,
                              item2.value_option__option
                            );
                          }}
                          id={`item2-${index2}`}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          onClick={() => {
                            updateDataValue(
                              item.name,
                              item2.value_option__option
                            );
                          }}
                          className="custom-control-input form-check-input"
                          id={`item2-${index2}`}
                          checked
                        />
                      )}
                      <label
                        className="custom-control-label form-check-label"
                        htmlFor={`item2-${index2}`}
                      >
                        {item2.value_option__option}
                      </label>
                    </div>
                  ))}
                {url.includes(`attr_name_${item.name}=${item.name}&attr_value_${item.name}=`) ? '' : item.values.length > 4 && (
                  <button
                    className="profile-edit-btn"
                    style={{background:'#fff',color:'#230BB3',fontWeight:'600'}}
                    onClick={() => handleToggleShowAll(item.name)}
                  >
                    {showAll[item.name] ? 'See Less' : 'See More'}
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : null
      )}




      

                  {
                       data4.map((item, index) => (
                        item.name === "brand" ? '' : (
                          <div className="collection-collapse-block open" style={{ display: item.values?.[0] ? "block" : "none" }} key={index}>
                            <h3
                              className={
                                item.name.length > 6
                                  ? "collapse-block-title dynamic-after2"
                                  : "collapse-block-title dynamic-after"
                              }
                              onClick={() => {
                                showattr[item.name] === false
                                  ? updateDataValue2(item.name, true)
                                  : updateDataValue2(item.name, false);
                              }}
                            >
                              {item.name}
                              <style>
                                {`.dynamic-after::after { left: 60px; }
                                  .dynamic-after2::after { left: 91px; }`}
                              </style>
                            </h3>
                            <div
                              className="collection-collapse-block-content"
                              style={{
                                display: showattr[item.name] === true ? "block" : "none",
                              }}
                            >
                              <div className="collection-brand-filter">


                              <div className="lastfilter-search-container">
                                    <input
                                        className="lastfilter-search__input"
                                        type="text"
                                        placeholder={`Search ${item.name}`}
                                  value={filterQueries[item.name] || ''}
                                  onChange={(e) => handleFilterChange(item.name, e.target.value)}
                                      />
                                      <FaSearch className="lastfilter-search-icon" />
                                    </div>
                            
                                {getFilteredValues(item).slice(0, showAlltwo[item.name] ? item.values.length : url.includes(`attr_name_${item.name}=${item.name}&attr_value_${item.name}=`) ? 50 : 4).map((item2, index2) => (
                                  <div className="custom-control custom-checkbox form-check collection-filter-checkbox" key={index2}>
                                    {!url.includes(
                                      `attr_name_${item.name}=${item.name}&attr_value_${item.name}=${item2.value_option__option}`
                                    ) ? (
                                      <input
                                        type="checkbox"
                                        className="custom-control-input form-check-input"
                                        onClick={() => {
                                          setpageSize(0);
                                          updateDataValue(item.name, item2.value_option__option);
                                        }}
                                        id={`item2-${index}-${index2}`}
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        onClick={() => {
                                          updateDataValue(item.name, item2.value_option__option);
                                        }}
                                        className="custom-control-input form-check-input"
                                        id={`item2-${index}-${index2}`}
                                        checked
                                      />
                                    )}
                                    <label
                                      className="custom-control-label form-check-label"
                                      htmlFor={`item2-${index}-${index2}`}
                                    >
                                      {item2.value_option__option}
                                    </label>
                                  </div>
                                ))}
                                {url.includes(`attr_name_${item.name}=${item.name}&attr_value_${item.name}=`) ? '' : item.values.length > 4 && (
                                  <button
                                    className="profile-edit-btn"
                    style={{background:'#fff',color:'#230BB3',fontWeight:'600'}}
                                    onClick={() => handleToggleShowAlltwo(item.name)}>
                                    {showAlltwo[item.name] ? 'See Less' : 'See More'}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      ))
                  }

                  {/* price filter start here */}
                  {data4?.[0]?.count ? <div className="collection-collapse-block border-0 open">
                    <h3
                      className="collapse-block-title dynamic-after3"
                      onClick={() => {
                        setprice(!price);
                      }}
                    >
                      price
                      <style>
                        {`.dynamic-after3::after {
            left:51px;
          }`}
                      </style>
                    </h3>

                    <p
                      style={{
                        paddingTop: "10px",
                        display: price == true ? "flex" : "none",
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>Min ₹{fixminpriceval == 0 ? fixminprice : fixminpriceval}</span>
                      <span>Max ₹{fixmaxpriceval == 0 ? fixmaxprice : fixmaxpriceval}</span>
                      {/* <span> ₹{pricevalue}</span> */}
                    </p>
                    <div
                      className="collection-collapse-block-content"
                      style={{ display: price == true ? "block" : "none" }}
                    >
                      <div
                        className="filter-slide"
                        style={{ paddingTop: "10px" }}
                      >
                        {/* <input
                          className="js-range-slider"
                          type="range"
                          min={fixminprice}
                          max={fixmaxprice}
                          name="my_range"
                          value={pricevalue}
                          onChange={(e) => {
                            setpricevalue(e.target.value);
                            // updateDataValue("offset", 0);
                            updateDataValueforsort("max_price", e.target.value);
                          }}
                          data-type="double"
                        /> */}
                        {/* {fixmaxprice == 0 ? '' : */}
                        {data[0] ?
                          <ReactSlider
                            style={{ background: 'red' }}
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            min={parseInt(fixminprice)}

                            value={[parseInt(fixminpriceval), parseInt(fixmaxpriceval)]}
                            max={parseInt(fixmaxprice)}
                            // defaultValue={[fixminprice, fixmaxprice]}
                            ariaLabel={['Lower thumb', 'Upper thumb']}
                            ariaValuetext={state => `Thumb value ${state.valueNow}`}
                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                            onAfterChange={(e) => { updateDataValueforsort("max_price", e) }}
                            // pearling
                            onChange={(e) => { setfixminpriceval(e[0]); setfixmaxpriceval(e[1]) }}
                            minDistance={100}
                          />
                          : <ReactSlider
                            style={{ background: 'red' }}
                            className="horizontal-slider"
                            thumbClassName="example-thumb"
                            trackClassName="example-track"
                            min={parseInt(fixminprice)}

                            defaultValue={[parseInt(fixminpriceval), parseInt(fixmaxpriceval)]}

                            max={parseInt(fixmaxprice)}
                            ariaLabel={['Lower thumb', 'Upper thumb']}
                            ariaValuetext={state => `Thumb value ${state.valueNow}`}
                            renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                            onAfterChange={(e) => { updateDataValueforsort("max_price", e) }}
                            onChange={(e) => { setfixminpriceval(e[0]); setfixmaxpriceval(e[1]) }}
                            // pearling
                            minDistance={100}
                          />}
                        {/* {console.log("first",parseInt(fixminpriceval),parseInt(fixmaxpriceval))} */}

                      </div>
                    </div>
                  </div> : ''}



                  {data4?.[0]?.count ? <div className="collection-collapse-block border-0 mt-4">
                    {/* <button>Clear All</button> */}
                    <input
                      style={{
                        outline: "none",
                        padding: "1px 1px",
                        fontSize: "14px",
                        fontWeight:'600',
                        color:'#230BB3',
                        background:'white'
                      }}
                      type="button"
                      onClick={() => { removefilter() }}
                      className="profile-edit-btn"
                      name="btnAddMore"
                      value="Clear All"
                    />
                  </div> : ''}













                </div>
              </div>

              {/* filter for sort by start here */}

              <div
                className="col-sm-3 collection-filter category-page-side"
                style={{
                  zIndex: currentwdith < 790 ? 9991 : 1,
                  left: "-15px",
                  display:
                    currentwdith < 700
                      ? sortby == true
                        ? "block"
                        : "none"
                      : "none",
                }}
              >
                {/* side-bar colleps block stat */}
                <div className="collection-filter-block creative-card creative-inner category-side">
                  {/* brand filter start */}
                  <div
                    className="collection-mobile-back"
                    onClick={() => {
                      setsortby(!sortby);
                    }}
                  >
                    <span className="filter-back">
                      <i className="fa fa-angle-left" aria-hidden="true" /> back
                    </span>
                  </div>
                  <div className="collection-collapse-block open">
                    <h3
                      className="collapse-block-title newarrow mt-0"
                      onClick={() => {
                        setbrand(!brand);
                      }}
                    >
                      Sort By
                    </h3>
                    <div
                      className="collection-collapse-block-content"
                      style={{ display: brand == true ? "block" : "none" }}
                    >
                      <div className="collection-brand-filter">
                        <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">
                          {/* <input
                            type="checkbox"
                            className="custom-control-input form-check-input"
                            id="forever-21"
                          /> */}
                          {url.includes(`sort_by=new_added`) ? (
                            <input
                              type="checkbox"
                              className="custom-control-input form-check-input"
                              onClick={() => {
                                // updateDataValue("offset", 0);
                                // updateDataValue("sort_by", "new_added");
                                updateDataValueforsort("sort_by", "new_added")
                              }}
                              id="item2"
                              checked
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onClick={() => {
                                // updateDataValue("offset", 0);
                                // updateDataValue("sort_by", "");
                                updateDataValueforsort("sort_by", "new_added")
                              }}
                              className="custom-control-input form-check-input"
                              id="item2"

                            />
                          )}
                          <label
                            className="custom-control-label form-check-label"
                            htmlFor="forever-21"
                          >
                            Newest First
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">
                          {url.includes(`sort_by=l2h`) ? (
                            <input
                              type="checkbox"
                              className="custom-control-input form-check-input"
                              onClick={() => {
                                // updateDataValue("offset", 0);
                                // updateDataValue("sort_by", "l2h");
                                updateDataValueforsort("sort_by", "l2h")
                              }}
                              id="item2"
                              checked
                            />
                          ) : url.includes(`sort_by=`) ? (
                            <input
                              type="checkbox"
                              className="custom-control-input form-check-input"
                              onClick={() => {
                                // updateDataValue("offset", 0);
                                // updateDataValue("sort_by", "l2h");
                                updateDataValueforsort("sort_by", "l2h")
                              }}
                              id="item2"
                              checked
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onClick={() => {
                                // updateDataValue("offset", 0);
                                // updateDataValue("sort_by", "");
                                updateDataValueforsort("sort_by", "l2h")
                              }}
                              className="custom-control-input form-check-input"
                              id="item2"

                            />
                          )}
                          {/* <input
                            type="checkbox"
                            className="custom-control-input form-check-input"
                            id="zara"
                          /> */}
                          <label
                            className="custom-control-label form-check-label"
                            htmlFor="zara"
                          >
                            Price-Low to High
                          </label>
                        </div>
                        <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">
                          {/* <input
                            type="checkbox"
                            className="custom-control-input form-check-input"
                            id="vera-moda"
                          /> */}
                          {url.includes(`sort_by=h2l`) ? (
                            <input
                              type="checkbox"
                              className="custom-control-input form-check-input"
                              onClick={() => {
                                // updateDataValue("offset", 0);
                                // updateDataValue("sort_by", "h2l");
                                updateDataValueforsort("sort_by", "h2l")
                              }}
                              id="item2"
                              checked
                            />
                          ) : (
                            <input
                              type="checkbox"
                              onClick={() => {
                                // updateDataValue("offset", 0);
                                // updateDataValue("sort_by", "");
                                updateDataValueforsort("sort_by", "h2l")
                              }}
                              className="custom-control-input form-check-input"
                              id="item2"

                            />
                          )}
                          <label
                            className="custom-control-label form-check-label"
                            htmlFor="vera-moda"
                          >
                            Price-High to Low
                          </label>
                        </div>

                        {/* <div className="custom-control custom-checkbox  form-check collection-filter-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input form-check-input"
                            id="roadster"
                          />
                          <label
                            className="custom-control-label form-check-label"
                            htmlFor="roadster"
                          >
                            Discount
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* filter for sort by end here */}
              <div className="collection-content col">
                <div className="page-main-content p-md-0">
                  <div className="row">
                    <div className="col-sm-12">
                      <div
                        className="collection-product-wrapper"
                        style={{ background: "#f9f9f9" }}
                      >
                        <div className="product-top-filter">
                          <div
                            className="row"
                            style={{ background: "#f9f9f9" }}
                          ></div>
                          <div className="row">
                            <div className="col-12">
                              <div className="product-filter-content">
                                <div
                                  className="search-count text-start"
                                  style={{
                                    border: "none",
                                    padding: "20px 0px 7px 0px",
                                  }}
                                >
                                  {/* <h5 style={{fontWeight: 400, fontSize: 13}}>Showing Products 1-24 of 10 Result</h5> */}
                                  {/* <h5
                                    style={{
                                      fontSize: 13,
                                      color: "black",
                                      fontWeight: 100,
                                    }}
                                  >
                                    Showing Products{" "}
                                    {console.log("currentPage  is here",currentPage,"this is it",pageSize)}
                                    {(currentPage == 1 ? currentPage : currentPage - 1) * pageSize }-
                                    {Math.min(
                                      (currentPage == 1 ? currentPage + 1 : currentPage ) * 12,
                                      totalrecords
                                    )}{" "}
                                    of {totalrecords} Result
                                  </h5> */}
                                  <h5 style={{
                                    fontSize: 13,
                                    color: "black",
                                    fontWeight: 100,
                                  }}>
                                    Showing Products{" "}
                                    {Math.min((currentPage - 1) * pageSize + 1, totalrecords)}-
                                    {Math.min(currentPage * pageSize, totalrecords)}{" "}
                                    of {totalrecords} Results
                                  </h5>

                                </div>
                                <div
                                  className="collection-view"
                                  style={{
                                    width: "100%",
                                    paddingBottom: "0px",
                                  }}
                                >
                                  <ul>
                                    <li
                                      style={{
                                        fontSize: 13,
                                        fontWeight: 600,
                                        paddingBottom: "4px",
                                      }}
                                    >
                                      Sort By
                                    </li>
                                    <li
                                      style={{
                                        fontSize: 13,
                                        marginRight: 12,
                                        paddingBottom: "4px",
                                        // color:
                                        // url.includes(`sort_by=new_added`) ? "#230bb3"
                                        //     : "",
                                        // fontWeight:
                                        // url.includes(`sort_by=new_added`) ? "550"
                                        //     : "400",
                                        // borderBottom:
                                        // url.includes(`sort_by=new_added`) ? "2px solid #230bb3"
                                        //     : "",
                                        color:
                                          url.includes(`sort_by=new_added`) ? "#230bb3"
                                            : url.includes(`sort_by`) ? "" : "#230bb3",
                                        fontWeight:
                                          url.includes(`sort_by=new_added`) ? "550"
                                            : url.includes(`sort_by`) ? "400" : "550",
                                        borderBottom:
                                          url.includes(`sort_by=new_added`) ? "2px solid #230bb3"
                                            : url.includes(`sort_by`) ? "" : "2px solid #230bb3",
                                      }}
                                      onClick={() => {
                                        // updateDataValue("offset", 0);
                                        updateDataValueforsort("sort_by", "new_added");
                                      }}
                                    >
                                      Newest First
                                    </li>
                                    <li
                                      style={{
                                        fontSize: 13,
                                        marginRight: 12,
                                        paddingBottom: "4px",
                                        // color:
                                        //   url.includes(`sort_by=l2h`) ? "#230bb3"
                                        //     : url.includes(`sort_by`) ? "" : "#230bb3",
                                        // fontWeight:
                                        //   url.includes(`sort_by=l2h`) ? "550"
                                        //     : url.includes(`sort_by`) ? "400" : "550",
                                        // borderBottom:
                                        // url.includes(`sort_by=l2h`) ? "2px solid #230bb3"
                                        //     : url.includes(`sort_by`) ? "" : "2px solid #230bb3",
                                        color:
                                          url.includes(`sort_by=l2h`) ? "#230bb3"
                                            : "",
                                        fontWeight:
                                          url.includes(`sort_by=l2h`) ? "550"
                                            : "400",
                                        borderBottom:
                                          url.includes(`sort_by=l2h`) ? "2px solid #230bb3"
                                            : "",
                                      }}
                                      onClick={() => {
                                        // updateDataValue("offset", 0);
                                        updateDataValueforsort("sort_by", "l2h");
                                      }}
                                    >
                                      Price - Low to High
                                    </li>
                                    <li
                                      style={{
                                        fontSize: 13,
                                        marginRight: 12,
                                        paddingBottom: "4px",
                                        color:
                                          url.includes(`sort_by=h2l`) ? "#230bb3"
                                            : "",
                                        fontWeight:
                                          url.includes(`sort_by=h2l`) ? "550"
                                            : "400",
                                        borderBottom:
                                          url.includes(`sort_by=h2l`) ? "2px solid #230bb3"
                                            : "",
                                      }}
                                      onClick={() => {
                                        // updateDataValue("offset", 0);
                                        updateDataValueforsort("sort_by", "h2l");
                                      }}
                                    >
                                      Price - High to Low
                                    </li>
                                    {/* <li style={{ fontSize: 13 }}>Discount</li> */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="product-wrapper-grid product">
                          <div className="row removepadding additionalgap" style={{ paddingBottom: data.length < 8 ? data.length < 4 ? '430px' : "310px" : "0px" }}>
                            {" "}
                            {loading == true ? (
                              <h2
                                style={{
                                  textAlign: "center",
                                  paddingTop: "15px",
                                  fontWeight: "600",
                                  fontSize: "20px",
                                }}
                              >
                                <Loader />
                              </h2>
                            ) : data[0] ? (
                              data.map((item, index) => {
                                var lowestOrderImage = getLowestOrderImage(item.image);
                                return (
                                  <div className="col-xl-3 col-md-4 col-sm-6 col-12">
                                    <div
                                      className="bg-white catbox"
                                      style={{ margin: "3px 4px" }}
                                    >
                                      <div className="product-imgbox " style={{ position: "relative", overflow: "hidden" }}>
                                      {item.stockrecords__num_in_stock <= 0 && (<div className="overlay" onClick={() => {
                                              transfer(item.id, item.title);
                                            }}></div>)}
                                        {item.stockrecords__num_in_stock <= 0 && (
                                          <div className="out-of-stock">Out of Stock</div>
                                        )}                                    
                                        {item.stockrecords__num_in_stock != 0 && item?.voucher_status == true ? (
                                          <div className="vouchervalid px-0" >
                                            <div className="specifywidth">
                                            <img
                        // src={`http://localhost:3000/images/greenbox.png`}
                        src={`${process.env.REACT_APP_API_PUBLIC_URL}images/greenbox.png`}
                        // className="img-fluid"
                        style={{width:'74%'}}
                        // style={{height:'100%',position:'absolute',left:'0px'}}
                        alt="logo"
                      />
                                 <p style={{position:"absolute",top: '6px',left: '4px',fontSize: '11px',fontWeight: '500'}}>   Voucher Applicable </p>
                                            </div>
                                            </div>
                                        ) : ''}                                    
                                          <div className="product-front">
                                          <button
                                            type="button"
                                            className="btn fixedhight"
                                            style={{ width: "100%" }}
                                            onClick={() => {
                                              transfer(item.id, item.title);
                                            }}
                                          >
                                            {" "}
                                            <img
                                              src={`${process.env.REACT_APP_API_URL}media/${lowestOrderImage?.original}`}
                                              className="img-fluid  "
                                              alt="product"
                                            />

                                            {" "}
                                          </button>
                                        </div>
                                      </div>
                                     
                                      <div className="product-detail detail-center detail-inverse">
                                        <div className="detail-title">
                                          <div className="detail-left">
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              {" "}
                                              <button
                                                type="button"
                                                className="btn"
                                                onClick={() => {
                                                  transfer(item.id, item.title);
                                                }}
                                              >
                                                <h6
                                                  className="price-title catbox2"
                                                  style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    color: "#230bb3",
                                                    height: '20px',
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: "1",
                                                    WebkitBoxOrient: "vertical",
                                                    overflow: "hidden",

                                                  }}
                                                >
                                                  {/* {item.title.length > 10 ? item.title.substring(0,18) + '...' : item.title} */}
                                                  {item.att_val.filter(item => item.attribute__name == "Brand" || item.attribute__name == "brand").map((item, index) => (
                                                    item.value_option__option
                                                  ))}
                                                </h6>{" "}
                                                <h6
                                                  className="price-title catbox2"
                                                  style={{
                                                    fontSize: "14px",
                                                    fontWeight: "600",
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: "1",
                                                    WebkitBoxOrient: "vertical",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {/* {item.title.length > 10 ? item.title.substring(0,18) + '...' : item.title} */}
                                                  {item?.title}
                                                </h6>{" "}
                                              </button>
                                            </div>
                                          </div>
                                          <div
                                            className="detail-right"
                                            style={{ width: "100%" }}
                                          >
                                            <div
                                              className="price"
                                              style={{ width: "100%" }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  color: "#000",
                                                  fontSize: "14px",
                                                  fontWeight: '500'
                                                }}
                                              >
                                                {" "}
                                                ₹{parseInt(item?.stockrecords__price)}{" "} {item?.stock_record?.discount == 0 ? '' : <span style={{ fontSize: '13px', color: '#c1c1c1', lineHeight: '20px', textDecoration: 'line-through', paddingLeft: '3px', fontWeight: '400' }}>₹{item?.stock_record?.mrp}</span>}{item?.stock_record?.discount == 0 ? '' : <span style={{ fontSize: '12px', color: '#388e3c', lineHeight: '20px', paddingLeft: '3px', fontWeight: '400' }}>{item?.stock_record?.discount_type == "amount" ? `(₹${item?.stock_record?.discount} off)` : `(${item?.stock_record?.discount} %off)`}</span>}
                                              </div>
                                              {/* <div className="price text-align-center" style={{display:'flex',justifyContent:'center'}}>  ₹{item.price} </div> */}
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div className="icon-detail">                             
                                <button className="tooltip-top add-cartnoty" onClick={()=>{Addtocart(item.id)}} data-tippy-content="Add to cart"> <AiOutlineShoppingCart size={45}/> </button>
                                <a href="javascript:void(0)" className="add-to-wish tooltip-top" data-tippy-content="Add to Wishlist"> <AiOutlineHeart size={45} /> </a>
                                <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#quick-view" className="tooltip-top" data-tippy-content="Quick View"> <AiOutlineEye size={45} /> </a>
                                
                              </div> */}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            ) : (
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img src={`${process.env.REACT_APP_API_PUBLIC_URL}images/notp.jpeg`}
                                  className="img-fluid  " alt="not Found" style={{ width: '36%' }} />
                                <h2 className="newtitleh2"
                                >
                                  But Don't Worry!! We will have Live Products on this Category Soon
                                </h2>
                              </div>
                            )}
                            {/* // <h2
                              //   style={{
                              //     textAlign: "center",
                              //     paddingTop: "17px",
                              //     fontWeight: "600",
                              //     fontSize: "20px",
                              //   }}
                              // >
                              //   No Result Found !
                              // </h2> */}
                          </div>
                        </div>
                        {/* {loading == true ? "" : data[0] ? ( */}
                        {showpages ?
                          <div className="product-pagination">
                            <div className="theme-paggination-block">
                              <div className="row mobilemargin">
                                <div className="col-xl-12 col-md-12 col-sm-12">
                                  <ReactPaginate
                                    pageCount={pageCount}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={2}
                                    onPageChange={(e) => {
                                      setCurrentPage(e.selected + 1);
                                      filterdata(e.selected * 40);
                                    }}
                                    containerClassName="pagination"
                                    breakClassName="page-item"
                                    activeClassName="active"
                                    pageClassName="page-item"
                                    previousLabel={
                                      <li className="page-item">
                                        <a
                                          className="page-link"
                                          href="javascript:void(0)"
                                          aria-label="Previous"
                                        >
                                          <span aria-hidden="true">
                                            <i
                                              className="fa fa-chevron-left"
                                              aria-hidden="true"
                                            />
                                          </span>{" "}
                                          <span className="sr-only">
                                            Previous
                                          </span>
                                        </a>
                                      </li>
                                    }
                                    nextLabel={
                                      <li className="page-item">
                                        <a
                                          className="page-link"
                                          href="javascript:void(0)"
                                          aria-label="Next"
                                        >
                                          <span aria-hidden="true">
                                            <i
                                              className="fa fa-chevron-right"
                                              aria-hidden="true"
                                            />
                                          </span>{" "}
                                          <span className="sr-only">Next</span>
                                        </a>
                                      </li>
                                    }
                                  />
                                </div>
                                {/* <div className="col-xl-6 col-md-6 col-sm-12">
                                <div
                                  className="product-search-count-bottom"
                                  style={{ borderLeft: "0px" }}
                                >
                                  <h5
                                    style={{
                                      fontSize: 13,
                                      color: "black",
                                      fontWeight: 100,
                                    }}
                                  >
                                    Showing Products{" "}
                                    {(currentPage == 0 ? currentPage : currentPage - 1) * pageSize }-
                                    {Math.min(
                                      (currentPage == 0 ? currentPage + 1 : currentPage ) * 12,
                                      totalrecords
                                    )}{" "}
                                    of {totalrecords} Result
                                  </h5>
                                </div>
                              </div> */}
                              </div>
                            </div>
                          </div>
                          : ''}

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="header7 bottomdgn" style={{zIndex:'4'}}>
                <div className="custom-container">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="header-contain"
                        style={{ padding: "8px 0px 0px 0px" }}
                      >
                        <div
                          className="collection-product-wrapper"
                          style={{ width: "100%" }}
                        >
                          <div className="product-top-filter">
                            <div className="row">
                              <div
                                className="col-xl-12"
                                style={{
                                  paddingTop: "10px",
                                  display: "flex",
                                  justifyContent: "space-around",
                                }}
                              >
                                <div
                                  className=""
                                  onClick={() => {
                                    setfilter(!filter);
                                  }}
                                >
                                  <span
                                    className="filter "
                                    style={{ paddingright: "100px" }}
                                  >
                                    <i
                                      className="fa fa-filter"
                                      aria-hidden="true"
                                      style={{
                                        fontSize: "24px",
                                        color: "#4150b5",
                                      }}
                                    />
                                  </span>
                                </div>

                                <div
                                  className="toggle-nav"
                                  style={{ justifyContent: "center" }}
                                  onClick={() => setshowsidebar(!showsidebar)}
                                >
                                  <i className="fa fa-bars sidebar-bar" />
                                </div>

                                <div className="collection-collapse">
                                  <h3
                                    className="collapse-block-title mt-0"
                                    onClick={() => {
                                      setsortby(!sortby);
                                    }}
                                  >
                                    <i
                                      class="fa-solid fa-arrow-down-wide-short"
                                      style={{ color: "#4150b5" }}
                                      onClick={() => {
                                        setsortby(!sortby);
                                      }}
                                    />
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section End */}

      {/* <Footer /> */}
    </>
  )}</>
};

export default Category;